import {
    RETAILERS_RECEIVE,
    RETAILERS_CITY_RECEIVE,
    RETAILERS_CHANGE_ITEMS_PER_PAGE,
    RETAILERS_CHANGE_TYPE,
    RETAILERS_SET_ORDERBY,
    RETAILERS_SEARCH,
    RETAILERS_SHOW_ON_MAP,
    RETAILERS_NEXT_PAGE,
    RETAILERS_PREV_PAGE,
    RETAILERS_GO_TO_PAGE,
    RETAILERS_REFRESH_ITEMS,
    RETAILERS_REFRESH_MARKERS,
} from "../Actions/Retailers.action"

const defaultState = {
    startIndex: 0,
    itemsPerPage: parseInt(sessionStorage.getItem("retailers_itemsPerPage") || 20),
    retailerType: null,
    typeFilter: "all",
    searchQuery: "",
    items: [],
    sourceItems: [],
    orderBy: sessionStorage.getItem("retailers_orderBy") || "name",
    markerObjects: [],
    activeMarker: {},
    categories: [],
    longitude: 14.717372,
    latitude: 59.610198,
    zoom: 6,
    city: null,
    loading: true,
}

export const retailers = (state = defaultState, action) => {
    const { type, payload } = action
    switch (type) {
        case RETAILERS_RECEIVE:
        case RETAILERS_CITY_RECEIVE:
        case RETAILERS_CHANGE_ITEMS_PER_PAGE:
        case RETAILERS_CHANGE_TYPE:
        case RETAILERS_SET_ORDERBY:
        case RETAILERS_SEARCH:
        case RETAILERS_SHOW_ON_MAP:
        case RETAILERS_PREV_PAGE:
        case RETAILERS_NEXT_PAGE:
        case RETAILERS_GO_TO_PAGE:
        case RETAILERS_REFRESH_ITEMS:
        case RETAILERS_REFRESH_MARKERS:
            return {
                ...state,
                ...payload,
            }
        default:
            return state
    }
}
