import {
    FACETED_SEARCH_RECEIVE,
    FACETED_SEARCH_TOGGLE_VALUE,
    FACETED_SEARCH_TOGGLE_COMPACT,
    FACETED_SEARCH_TOGGLE_VISIBILITY,
    FACETED_SEARCH_LOAD_MORE_PRODUCTS,
} from "../Actions/FacetedSearch.action"
const defaultState = {
    subNavigation: [],
    sortCriteria: [],
    facetFilters: [],
    visibleDropdownMenu: [],
    navigationTheme: "filter",
    productsViewCachedId: null,
    visibility: true,
    pageNumber: 1,
}

export const facetedSearch = (state = defaultState, action) => {
    const { type, payload } = action
    switch (type) {
        case FACETED_SEARCH_TOGGLE_VISIBILITY:
        case FACETED_SEARCH_RECEIVE:
        case FACETED_SEARCH_LOAD_MORE_PRODUCTS:
            return {
                ...state,
                ...payload,
            }
        case FACETED_SEARCH_TOGGLE_VALUE:
            return {
                ...state,
                facetFilters: payload.facetFilters,
            }
        case FACETED_SEARCH_TOGGLE_COMPACT: {
            const { visibleDropdownMenu } = payload
            return {
                ...state,
                visibleDropdownMenu: state.visibleDropdownMenu.includes(visibleDropdownMenu) ? [] : [visibleDropdownMenu],
            }
        }
        default:
            return state
    }
}
