import React, { Fragment, Component } from "react"
import ProductDropdown from "./Products/ProductDropdown"
import ProductFilterList from "./Products/ProductFilterList"

class FacetedSearchGroup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
            showLessItemCount: 5,
        }
        this.toggleShowMore = this.toggleShowMore.bind(this)
    }

    componentDidMount() {
        /*const visible = this.refs.list.getBoundingClientRect().height !== 0;
        const tooMuchItem = this.props.group.options.length > this.state.showLessItemCount;
        visible && tooMuchItem && this.toggleShowMore();*/
    }

    toggleShowMore() {
        const { list, showLess, showMore } = this.refs
        const topPos = list.getBoundingClientRect().top
        const lessBottomPos = showLess.getBoundingClientRect().bottom
        const moreBottomPos = showMore.getBoundingClientRect().bottom
        const setHeight = () => (list.style.height = `${(this.state.collapsed ? lessBottomPos : moreBottomPos) - topPos}px`)
        const toggleCollapse = () => {
            this.setState(
                (prevState) => ({
                    collapsed: !prevState.collapsed,
                }),
                () => {
                    setHeight()
                }
            )
        }
        if (!list.style.height) {
            setHeight()
            setTimeout(() => {
                toggleCollapse()
            }, 0)
        } else {
            toggleCollapse()
        }
    }

    render() {
        const { group, searchFacetChange } = this.props
        return (
            <Fragment>
                <div className="subnav__parent--container h-mr-sm" role="faceted-search-item">
                    <div className="subnav__parent">
                        <ProductDropdown
                            label={group.label}
                            shouldRedirect={false}
                            labelField={"label"}
                            valueField={"id"}
                            isMultiSelect={!group.singleSelect}
                            values={group.selectedOptions}
                            onChange={(item) => searchFacetChange(group, item)}
                            options={group.options}
                        />
                    </div>
                </div>
            </Fragment>
        )
    }
}

const FacetedSearch = ({ facetFilters, query, searchFacetChange, navigationTheme, visibility }) =>
    navigationTheme !== "category" && (
        <Fragment>
            <ul className={"faceted-search h-m-zero h-p-zero" + (facetFilters && visibility ? " active" : "")}>
                {facetFilters &&
                    visibility &&
                    facetFilters.map((group, groupIndex) => (
                        <FacetedSearchGroup key={`${group.label}-${groupIndex}`} group={group} searchFacetChange={searchFacetChange} />
                    ))}
            </ul>
            <ProductFilterList facetFilters={facetFilters} searchFacetChange={searchFacetChange} />
        </Fragment>
    )

const FacetedFilterCheckbox = ({ item, group, searchFacetChange }) => (
    <label className="faceted-filter">
        <input
            className="faceted-filter__input"
            type="checkbox"
            onChange={() => searchFacetChange(group, item)}
            checked={group.selectedOptions != null && group.selectedOptions.includes(item.value)}
        />
        <span className="faceted-filter__label">
            {item.label}
            {!isNaN(item.quantity) && <span className="faceted-filter__quantity">&nbsp;({item.quantity})</span>}
        </span>
    </label>
)

export default FacetedSearch
