// External libraries
import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { applyMiddleware, bindActionCreators, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"
import thunk from "redux-thunk"
import $ from "jquery"
import "foundation-sites"
import "slick-carousel"
import Masonry from "masonry-layout"
import { setUpSliders, initSelect } from "./misc.js"

// Reducers
import app from "./reducers.js"

// Middlewares
import { historyMiddleware } from "./Middlewares/History.middleware.js"

// Services
import { translate } from "./Services/translation.js"
import { post } from "./Services/http.js"

// Actions
import { add as addToCart, reorder, update as updateCart, load as loadCart } from "./Actions/Cart.action.js"
import { toggleVisibility, loadMoreProducts } from "./Actions/FacetedSearch.action.js"
import {
    setImages as setProductImageSlideShows,
    show as showProductImageSlideShow,
    setCurrentIndex as setProductImageSlideShowIndex,
} from "./Actions/LightboxImages.action.js"

// Containers
import MiniCartContainer from "./Containers/MiniCart.container.js"
import QuickSearchContainer from "./Containers/QuickSearch.container.js"
import NavigationContainer from "./Containers/Navigation.container"
import FacetedSearchContainer from "./Containers/FacetedSearch.container.js"
import FacetedSearchCompactContainer from "./Containers/FacetedSearchCompact.container.js"
import NotificationContainer from "./Containers/Notification.container.js"
import NWPContainer from "./Components/NWPCalculator/Containers/NwpCalculator.container.js"

// Components
import DynamicComponent from "./Components/DynamicComponent.js"

// Vanilla JS
import heroMediaBlock from "./hero-media-block"
heroMediaBlock()

// Constants
window.__litium = window.__litium || {}
const preloadState = window.__litium.preloadState || {}
const store = createStore(app, preloadState, composeWithDevTools(applyMiddleware(thunk, historyMiddleware)))
const bootstrapComponents = () => {
    initSelect()

    const insufficientStockMessageElement = document.getElementById("insufficientStockMessage")

    if (insufficientStockMessageElement) {
        const InsufficientStockMessageComponent = DynamicComponent({
            loader: () => import("./Components/InsufficientStockMessage.js"),
        })
        const isInStock = insufficientStockMessageElement.dataset.isInStock === "true" ? true : false
        const articleNumber = insufficientStockMessageElement.dataset.articleNumber
        ReactDOM.render(
            <Provider store={store}>
                <InsufficientStockMessageComponent isInStock={isInStock} articleNumber={articleNumber} />
            </Provider>,
            insufficientStockMessageElement
        )
    }

    if (document.getElementById("miniCart")) {
        ReactDOM.render(
            <Provider store={store}>
                <MiniCartContainer />
            </Provider>,
            document.getElementById("miniCart")
        )
    }

    if (document.getElementById("quickSearch")) {
        ReactDOM.render(
            <Provider store={store}>
                <QuickSearchContainer />
            </Provider>,
            document.getElementById("quickSearch")
        )
    }

    if (document.getElementById("pickupDelivery")) {
        const PickupDelivery = DynamicComponent({
            loader: () => import("./Components/PickupDelivery.js"),
        })
        const pickupDeliveryContainer = document.getElementById("pickupDelivery")
        const pickupDeliveryOpen = pickupDeliveryContainer.dataset.modalopen === "true" ? true : false
        ReactDOM.render(
            <Provider store={store}>
                <PickupDelivery open={pickupDeliveryOpen} />
            </Provider>,
            pickupDeliveryContainer
        )
    }

    if (document.getElementById("navbar")) {
        ReactDOM.render(
            <Provider store={store}>
                <NavigationContainer />
            </Provider>,
            document.getElementById("navbar")
        )
    }

    if (document.getElementById("facetedSearch")) {
        ReactDOM.render(
            <Provider store={store}>
                <FacetedSearchContainer />
            </Provider>,
            document.getElementById("facetedSearch")
        )
    }

    if (document.getElementById("nwpCalculatorX")) {
        ReactDOM.render(
            <Provider store={store}>
                <NWPContainer company={__litium.siteContext} />
            </Provider>,
            document.getElementById("nwpCalculatorX")
        )
    }

    if (document.getElementById("facetedSearchCompact")) {
        const facetedSearchCompactContainer = document.getElementById("facetedSearchCompact")
        ReactDOM.render(
            <Provider store={store}>
                <FacetedSearchCompactContainer pageNumber={facetedSearchCompactContainer.dataset.pageNumber} />
            </Provider>,
            document.getElementById("facetedSearchCompact")
        )
    }

    if (document.getElementById("globalNotification")) {
        ReactDOM.render(
            <Provider store={store}>
                <NotificationContainer />
            </Provider>,
            document.getElementById("globalNotification")
        )
    }

    if (document.getElementById("miniCart")) {
        ReactDOM.render(
            <Provider store={store}>
                <MiniCartContainer />
            </Provider>,
            document.getElementById("miniCart")
        )
    }

    if (document.getElementById("myPagePersons")) {
        const PersonList = DynamicComponent({
            loader: () => import("./Containers/PersonList.container.js"),
        })
        ReactDOM.render(
            <Provider store={store}>
                <PersonList />
            </Provider>,
            document.getElementById("myPagePersons")
        )
    }

    if (document.getElementById("myPageAddresses")) {
        const AddressList = DynamicComponent({
            loader: () => import("./Containers/AddressList.container.js"),
        })
        ReactDOM.render(
            <Provider store={store}>
                <AddressList />
            </Provider>,
            document.getElementById("myPageAddresses")
        )
    }

    if (document.getElementById("checkout")) {
        const Checkout = DynamicComponent({
            loader: () => import("./Containers/Checkout.container.js"),
        })
        ReactDOM.render(
            <Provider store={store}>
                <Checkout />
            </Provider>,
            document.getElementById("checkout")
        )
    }

    if (document.getElementById("downloadDocumentsPage")) {
        const DownloadDocuments = DynamicComponent({
            loader: () => import("./Containers/DownloadDocuments.container.js"),
        })
        ReactDOM.render(
            <Provider store={store}>
                <DownloadDocuments />
            </Provider>,
            document.getElementById("downloadDocumentsPage")
        )
    }

    if (document.getElementById("lightBoxImages")) {
        const LightboxImages = DynamicComponent({
            loader: () => import("./Containers/LightboxImages.container.js"),
        })
        const showThumbnails = document.getElementById("lightBoxImages").getAttribute("data-showThumbnails") === "true" ? true : false
        ReactDOM.render(
            <Provider store={store}>
                <LightboxImages showThumbnails={showThumbnails} />
            </Provider>,
            document.getElementById("lightBoxImages")
        )
    }

    if (document.getElementById("nwpCalculator")) {
        const blockId = document.getElementById("nwpCalculator").dataset.calculatorBlockId

        const NwpCalculatorContainer = DynamicComponent({
            loader: () => import("./Components/NWPCalculator/Containers/NwpCalculator.container.js"),
        })
        ReactDOM.render(
            <Provider store={store}>
                <NwpCalculatorContainer company={__litium.siteContext} blockId={blockId} />
            </Provider>,
            document.getElementById("nwpCalculator")
        )
    }

    if (document.getElementById("productWarrantyPdfForm")) {
        const ProductWarrantyPdfForm = DynamicComponent({
            loader: () => import("./Components/Pdf/ProductWarrantyPdfForm.js"),
        })
        ReactDOM.render(
            <Provider store={store}>
                <ProductWarrantyPdfForm />
            </Provider>,
            document.getElementById("productWarrantyPdfForm")
        )
    }

    if (document.getElementById("retailers")) {
        const RetailersContainer = DynamicComponent({
            loader: () => import("./Containers/Retailers.container.js"),
        })
        const retailersContainer = document.getElementById("retailers")
        const retailersType = retailersContainer.dataset.type
        ReactDOM.render(
            <Provider store={store}>
                <RetailersContainer retailerType={retailersType} />
            </Provider>,
            retailersContainer
        )
    }

    if (document.getElementById("trebolitCalculator")) {
        const TrebolitCalculatorContainer = DynamicComponent({
            loader: () => import("./Containers/TrebolitCalculator.container.js"),
        })
        ReactDOM.render(
            <Provider store={store}>
                <TrebolitCalculatorContainer />
            </Provider>,
            document.getElementById("trebolitCalculator")
        )
    }

    if (document.querySelectorAll(".video__player-component").length > 0) {
        const VideoPlayer = DynamicComponent({
            loader: () => import("./Components/VideoPlayer.js"),
        })
        Array.from(document.querySelectorAll(".video__player-component")).forEach((videoPlayer) => {
            const values = {
                video: videoPlayer.dataset.video,
                videoFormat: videoPlayer.dataset.videoFormat,
                autoplay: videoPlayer.dataset.autoplay !== undefined ? true : false,
                muted: videoPlayer.dataset.muted !== undefined ? true : false,
                loop: videoPlayer.dataset.loop !== undefined ? true : false,
            }

            if (values !== undefined) {
                ReactDOM.render(<VideoPlayer values={values} />, videoPlayer)
            }
        })
    }

    if (document.querySelectorAll(".contact-form__component").length > 0) {
        const ContactForm = DynamicComponent({
            loader: () => import("./Components/ContactForm.js"),
        })
        Array.from(document.querySelectorAll(".contact-form__component")).forEach((contactForm) => {
            ReactDOM.render(<ContactForm />, contactForm)
        })
    }

    if (document.querySelectorAll(".education-form__component").length > 0) {
        const EducationForm = DynamicComponent({
            loader: () => import("./Components/EducationForm.js"),
        })
        Array.from(document.querySelectorAll(".education-form__component")).forEach((educationForm) => {
            ReactDOM.render(<EducationForm />, educationForm)
        })
    }

    if (document.querySelectorAll(".accordion-component").length > 0) {
        const Accordion = DynamicComponent({
            loader: () => import("./Components/Accordion.js"),
        })
        Array.from(document.querySelectorAll(".accordion-component")).forEach((accordion) => {
            const { title, text, styling } = accordion.dataset
            const maximized = accordion.dataset.maximized === "True"
            ReactDOM.render(<Accordion styling={styling} title={title} text={text} maximized={maximized} html={accordion.innerHTML} />, accordion)
        })
    }

    if (document.querySelectorAll(".numericupdown-component").length > 0) {
        const NumericUpDown = DynamicComponent({
            loader: () => import("./Components/NumericUpDown.js"),
        })
        Array.from(document.querySelectorAll(".numericupdown-component")).forEach((numericupdown) => {
            const {
                min,
                max,
                id,
                quantity,
                initialValue,
                expression,
                expressionFormat,
                expressionQuantityName,
                multiple,
                decimals,
                onlyFullPackage,
            } = numericupdown.dataset
            ReactDOM.render(
                <NumericUpDown
                    minValue={min}
                    maxValue={max}
                    inputId={id}
                    quantity={quantity}
                    initialValue={initialValue}
                    dataExpression={expression}
                    dataExpressionFormat={expressionFormat}
                    dataExpressionQuantityName={expressionQuantityName}
                    multiple={multiple}
                    decimals={JSON.parse(decimals)}
                    onlyFullPackage={onlyFullPackage}
                />,
                numericupdown
            )
        })
    }

    if (document.querySelectorAll(".documents-component").length > 0) {
        const DocumentsContainer = DynamicComponent({
            loader: () => import("./Containers/Documents.container.js"),
        })
        Array.from(document.querySelectorAll(".documents-component")).forEach((item) => {
            ReactDOM.render(
                <Provider store={store}>
                    <DocumentsContainer folderId={item.dataset.id} />
                </Provider>,
                item
            )
        })
    }

    var tmb = document.getElementById("technicalMenuBlock")
    if (tmb && tmb.length > 0) {
        const NumericUpDown = DynamicComponent({
            loader: () => import("./Components/NumericUpDown.js"),
        })
        Array.from(document.querySelectorAll(".numericupdown-component")).forEach((numericupdown) => {
            const { min, max, id, quantity, initialValue, expression, expressionFormat, expressionQuantityName, multiple, decimals } = numericupdown.dataset
            ReactDOM.render(
                <NumericUpDown
                    minValue={min}
                    maxValue={max}
                    inputId={id}
                    quantity={quantity}
                    initialValue={initialValue}
                    dataExpression={expression}
                    dataExpressionFormat={expressionFormat}
                    dataExpressionQuantityName={expressionQuantityName}
                    multiple={multiple}
                    decimals={JSON.parse(decimals)}
                />,
                numericupdown
            )
        })
    }

    window.onload = () => {
        var element = document.getElementById("popupvideo-component")
        if (element) {
            const PopupVideo = DynamicComponent({
                loader: () => import("./Components/PopupVideo.js"),
            })

            ReactDOM.render(<PopupVideo />, element)
        }
    }

    if (document.querySelectorAll(".municipalitydropdown-component").length > 0) {
        const MunicipalitiesContainer = DynamicComponent({
            loader: () => import("./Containers/Municipalities.container.js"),
        })
        Array.from(document.querySelectorAll(".municipalitydropdown-component")).forEach((municipalitiesCon) => {
            const id = municipalitiesCon.dataset.id
            const selectedKeys = JSON.parse(municipalitiesCon.dataset.selected)
            ReactDOM.render(
                <Provider store={store}>
                    <MunicipalitiesContainer inputId={id} selectedKeys={selectedKeys} />
                </Provider>,
                municipalitiesCon
            )
        })
    }

    if (document.querySelectorAll(".slider").length > 0) {
        const Slider = DynamicComponent({
            loader: () => import("./Components/Slider.js"),
        })
        Array.from(document.querySelectorAll(".slider")).forEach((slider) => {
            const values = [...slider.querySelectorAll(".slider__block")].map((block) => {
                const { image, url, text } = block.dataset
                return { image, url, text }
            })
            if (values.length > 0) {
                ReactDOM.render(<Slider values={values} />, slider)
            }
        })
    }

    if (document.getElementById("technicalMenuBlock")) {
        const TechnialMenuBlock = DynamicComponent({
            loader: () => import("./Components/TechnialMenuBlock.js"),
        })
        ReactDOM.render(
            <Provider store={store}>
                <TechnialMenuBlock />
            </Provider>,
            document.getElementById("technicalMenuBlock")
        )
    }

    $(".tab__content .tab__header").click((e) => {
        const elem = $(e.currentTarget).parent()
        document.querySelectorAll(".tab__content .tab__header").forEach((tab) => {
            tab.classList.remove("active")
        })
        e.currentTarget.classList.toggle("active")
        setTimeout(() => {
            const offset = elem.offset().top - $(".header").outerHeight() - 40
            $("html, body").animate(
                {
                    scrollTop: offset,
                },
                400
            )
        }, 250)
    })

    // Stock status
    ;(() => {
        let leavingStockCounter
        if ($(".product-detail__stock-status").length > 0) {
            $(".product-detail__stock-status-toggle").click((e) => {
                const toggle = $(e.currentTarget)
                const list = toggle.parent().find(".product-detail__stock-status-list")
                toggle.toggleClass("active")
                list.toggleClass("active", toggle.hasClass("active"))
            })
            $(".product-detail__stock-status")
                .mouseleave((e) => {
                    const elem = $(e.currentTarget)
                    clearTimeout(leavingStockCounter)
                    leavingStockCounter = setTimeout(() => {
                        elem.find(".product-detail__stock-status-toggle, .product-detail__stock-status-list").removeClass("active")
                    }, 300)
                })
                .mouseenter(() => {
                    clearTimeout(leavingStockCounter)
                })
        }
    })()
    ;(() => {
        const stickyElement = $(".sticky")
        const scrollThreshold = 20
        const startShrinkingAt = 60
        let lastScrollTop = 0

        function handleScroll() {
            const scrollTop = $(window).scrollTop()

            // Check if the scroll position has changed significantly
            // The shrinking of the logo causes the page to scroll, and having the
            // scroll threshold check in place should prevent an infinite scroll loop from occuring.
            if (Math.abs(scrollTop - lastScrollTop) > scrollThreshold) {
                stickyElement.toggleClass("scroll", scrollTop > startShrinkingAt)
                lastScrollTop = scrollTop
            }
        }

        // Initial execution
        handleScroll()

        // Attach the scroll event handler
        $(window).scroll(handleScroll)
    })()

    $(document).ready(() => {
        $(".form__input-group .form__input")
            .on("change keyup", (e) => checkInputGroup($(e.currentTarget)))
            .each((index, element) => checkInputGroup($(element)))
        setUpSliders()
        setUpMenu()
        setUpGridImageHandler()
    })

    $('a[href^="#"]').each((index, anchor) => {
        $(anchor).click((e) => {
            e.preventDefault()
            var elem = $(anchor).attr("href")
            if (elem.length > 0 && elem !== "#") {
                $("html, body").animate(
                    {
                        scrollTop: $($(anchor).attr("href")).offset().top - $(".sticky").outerHeight() - 30,
                    },
                    300
                )
            }
        })
    })

    if (document.getElementById("SendWarrantyCertificateModal")) {
        var modal = new Foundation.Reveal($("#SendWarrantyCertificateModal"))
    }
}

// Functions
function checkInputGroup(input) {
    input
        .parent()
        .find(".form__label-hidden")
        .toggleClass("active", input.val() !== "")
}

function setUpGridImageHandler() {
    const lbDiv = document.querySelectorAll("#lightBoxImages")
    if (lbDiv.length == 0) {
        let lbi = document.createElement("div")
        lbi.id = "lightBoxImages"
        document.body.appendChild(lbi)
        const LightboxImages = DynamicComponent({
            loader: () => import("./Containers/LightboxImages.container.js"),
        })
        const showThumbnails = document.getElementById("lightBoxImages").getAttribute("data-showThumbnails") === "true" ? true : false
        ReactDOM.render(
            <Provider store={store}>
                <LightboxImages showThumbnails={showThumbnails} />
            </Provider>,
            document.getElementById("lightBoxImages")
        )
    }
    const gridImages = document.querySelectorAll(".grid-block-img")
    if (gridImages.length > 0) {
        Array.from(gridImages).forEach((row) => {
            if (!row.classList.contains("slick-slider")) {
                $(row).not(".slick-initialized").slick({
                    slidesToShow: 1,
                    arrows: false,
                    infinite: false,
                    asNavFor: ".product-detail__image-thumbs",
                    draggable: false,
                    speed: 200,
                    fade: true,
                    adaptiveHeight: true,
                })
            }
        })
    }
}

function heroMinHeight() {
    $(".hero-block").each((index, block) => {
        $(block).css("min-height", Math.round($(block).find(".overlay").outerHeight()))
    })
}

function setUpMenu() {
    if (window.outerWidth >= 1024) {
        const elements = document.querySelectorAll(".menu_level_1 > .navbar__items-container > ul")
        if (elements.length > 0) {
            Array.from(elements).forEach((elem) => {
                var msnry = new Masonry(elem, {})
                setTimeout(() => {
                    msnry.layout()
                }, 5000)
            })
        }
    }
}

// Init
if (!String.prototype.format) {
    String.prototype.format = function () {
        const args = arguments
        return this.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != "undefined" ? args[number] : match
        })
    }
}

window.__litium = {
    ...window.__litium,
    reduxActions: bindActionCreators(
        {
            addToCart,
            updateCart,
            reorder,
            setProductImageSlideShows,
            showProductImageSlideShow,
            setProductImageSlideShowIndex,
            toggleVisibility,
            loadMoreProducts,
            loadCart,
        },
        store.dispatch
    ),
    events: {
        onTogglePickup: (warehouseId) => {
            if (!warehouseId) {
                return
            }

            document.cookie = `warehouseId=${warehouseId}; Path=/;`
            document.cookie = "deliveryMethod=pickup; Path=/;"

            setTimeout(() => {
                window.location.reload()
            }, 250)
        },
        onToggleFavoriteItem: (e, guid) => {
            post("/api/favorite/ToggleFavorite/", { baseProductSystemId: guid })
                .then((response) => response.json())
                .then((result) => {
                    if (e.classList.contains("product-favorite__heart")) {
                        e.classList.toggle("favorite", result)
                    }
                })
        },
        onToggleClass: (e, className, target, level) => {
            if (e && className && e.classList) {
                if (!level && !target) {
                    e.classList.toggle(className)
                } else {
                    if (target && level) {
                        switch (level) {
                            case "sibling":
                                if (e.parentElement) {
                                    const parent = e.parentElement
                                    for (const sibling of parent.children) {
                                        if (sibling.classList.contains(target)) {
                                            sibling.classList.toggle(className)
                                        }
                                    }
                                }
                                break

                            default:
                                if (e.children && target) {
                                    for (const child of e.children) {
                                        if (child.classList.contains(target)) {
                                            child.classList.toggle(className)
                                        }
                                    }
                                }
                                break
                        }
                    } else if (target) {
                        if (target.indexOf("#") == 0) {
                            // This is an ID
                            const id = target.substring(1, target.length)
                            const element = document.getElementById(id)

                            element.classList.toggle(className)
                        }
                    }
                }
            }
        },
        onToggleVisibility: (e) => {
            const newValue = window.__litium.reduxActions.toggleVisibility()
            const text = e.children[0]
            const arrow = e.children[1]

            if (text && arrow) {
                if (newValue) {
                    text.innerText = translate("hidefilters")

                    if (arrow) {
                        arrow.classList.add("active")
                    }
                } else {
                    text.innerText = translate("showfilters")

                    if (arrow) {
                        arrow.classList.remove("active")
                    }
                }
            }
        },
        onAddToCartButtonClick: (sourceDomNode, articleNumber, quantityFieldId = null) => {
            const nodeIdToShowNotification = (sourceDomNode.id = Date.now())
            window.__litium.reduxActions
                .addToCart({
                    articleNumber,
                    quantity: quantityFieldId ? document.getElementById(quantityFieldId).value : 1,
                    nodeIdToShowNotification,
                    notificationMessage: translate("tooltip.addedtocart"),
                    hash: Date.now(),
                })
                .then(() => window.__litium.reduxActions.loadCart())
        },
        onUpdateCartButtonClick: (orderid, quantityFieldId = null) => {
            window.__litium.reduxActions
                .updateCart(orderid, quantityFieldId ? (isNaN(quantityFieldId) ? document.getElementById(quantityFieldId).value : 1) : quantityFieldId)
                .then(() => window.__litium.reduxActions.loadCart())
        },
        onReorderClick: (orderid) => {
            if (orderid) {
                window.__litium.reduxActions.reorder({ orderid })
            }
        },
        onProductImageClick: (ele) => {
            const productImagesSelector = "product-detail__main-image-slider"
            const productImageSelector = "product-image"
            const closest = (el, className) => (el ? (el.classList.contains(className) ? el : closest(el.parentNode, className)) : null)
            const parentNodes = ele.closest ? ele.closest(`.${productImagesSelector}`) : closest(ele, productImagesSelector)
            const images = Array.from(parentNodes.querySelectorAll(`.${productImageSelector}`)).map((img) => ({ src: img.dataset.src }))
            const index = images.findIndex((_ele) => ele.dataset.src === _ele.src)

            window.__litium.reduxActions.setProductImageSlideShows(images)
            window.__litium.reduxActions.showProductImageSlideShow(true)
            window.__litium.reduxActions.setProductImageSlideShowIndex(index)
        },
        onImageClick: (e, ele) => {
            e.preventDefault()

            const images = [
                {
                    src: ele.dataset.src,
                },
            ]

            window.__litium.reduxActions.setProductImageSlideShows(images)
            window.__litium.reduxActions.showProductImageSlideShow(true)
            window.__litium.reduxActions.setProductImageSlideShowIndex(0)
        },
        onGridImageClick: (ele) => {
            var images = [
                {
                    src: ele.dataset.src,
                },
            ]

            window.__litium.reduxActions.setProductImageSlideShows(images)

            window.__litium.reduxActions.showProductImageSlideShow(true)

            window.__litium.reduxActions.setProductImageSlideShowIndex(0)
        },
        onVideoPlayerClick: (html) => {
            if (html) {
                var component = document.getElementById("popupvideo-component")

                if (component) {
                    var parentContainer = component.getElementsByClassName("popup-video__parent-container")[0]

                    if (parentContainer && !parentContainer.classList.contains("open")) {
                        parentContainer.classList.add("open")

                        var close = parentContainer.getElementsByClassName("popup-video__close")[0]
                        close.onclick = () => {
                            window.__litium.events.onVideoPlayerClose(parentContainer)
                        }

                        var outside = parentContainer.getElementsByClassName("popup-video__backdrop")[0]
                        outside.onclick = () => {
                            window.__litium.events.onVideoPlayerClose(parentContainer)
                        }

                        var container = parentContainer.getElementsByClassName("popup-video__container")[0]
                        container.innerHTML = html
                    }
                }
            }
        },
        onVideoPlayerClose: (e) => {
            if (e && e.classList.contains("open")) {
                e.classList.remove("open")

                var container = e.getElementsByClassName("popup-video__container")[0]
                container.innerHTML = ""
            }
        },
        onLoadMoreProducts: async (clickTarget) => {
            let hideAtCount = 0

            if (clickTarget && clickTarget.dataset.hideAt) {
                hideAtCount = parseInt(clickTarget.dataset.hideAt, 10)
            }

            const currentPage = store.getState().facetedSearch.pageNumber

            if (hideAtCount && hideAtCount === currentPage + 1) {
                clickTarget.style.display = "none"
            }

            await window.__litium.reduxActions.loadMoreProducts(currentPage)

            const url = new URL(location)
            url.searchParams.set("page", currentPage + 1)
            history.replaceState({}, "", url)
        },
        onSetPageNumberReference: (pageNumber) => {
            document.cookie = `pageNumber=${pageNumber}; path=/`
            document.cookie = `pageUrl=${window.location.pathname + window.location.search}; path=/`
        },
    },
    bootstrapComponents: () => {
        // bootstrap React components, in case the HTML response we receive from the server
        // has React components. ReactDOM.render performs only an update on previous rendered
        // components and only mutate the DOM as necessary to reflect latest React element.
        bootstrapComponents()
    },
    cache: {}, // for storing cache data for current request
}

bootstrapComponents()
