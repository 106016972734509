import { ADD_SELECTED_PRODUCTS, ADD_SELECTED_DOCUMENTS } from "../Actions/DownloadDocuments.action"

function loadStateFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem("downloadDocuments")
        if (serializedState === null) return undefined
        return JSON.parse(serializedState)
    } catch (e) {
        return undefined
    }
}

const initialState = loadStateFromLocalStorage() || {
    selectedProducts: [],
    selectedDocuments: [],
}

export const downloadDocuments = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case ADD_SELECTED_PRODUCTS:
            const newProductsState = {
                ...state,
                selectedProducts: payload,
            }
            localStorage.setItem("downloadDocuments", JSON.stringify(newProductsState))
            return newProductsState
        case ADD_SELECTED_DOCUMENTS:
            const newDocumentsState = {
                ...state,
                selectedDocuments: payload,
            }
            localStorage.setItem("downloadDocuments", JSON.stringify(newDocumentsState))
            return newDocumentsState
        default:
            return state
    }
}
