import {
    TREBOLITCALCULATOR_LOGIN,
    TREBOLITCALCULATOR_SWITCH_STATE,
    TREBOLITCALCULATOR_SET_CURRENTUSER,
    TREBOLITCALCULATOR_LOGIN_MESSAGE,
} from "../Actions/TrebolitCalculator.action"
import { TrebolitCalculatorStates } from "../Constants/TrebolitCalculator.constants"

export const trebolitCalculator = (
    state = { forgetPasswordLink: "", registrationLink: "", loginMessage: "", currentUser: {}, currentState: TrebolitCalculatorStates.loading },
    action
) => {
    const { type, payload } = action
    switch (type) {
        case TREBOLITCALCULATOR_LOGIN_MESSAGE:
        case TREBOLITCALCULATOR_SET_CURRENTUSER:
        case TREBOLITCALCULATOR_SWITCH_STATE:
        case TREBOLITCALCULATOR_LOGIN:
            return {
                ...state,
                ...payload,
            }
        default:
            return state
    }
}
