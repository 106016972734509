function heroMediaBlock() {
    const blockSelector = "[data-js-hero-media-block]"
    const playButtonSelector = "[data-js-hero-media-block-play-button]"
    const playButtonTextSelector = "[data-js-hero-media-block-button-text]"
    const mediaWrapperSelector = "[data-js-hero-media-block-media-wrapper]"
    const isPlayingClass = "is-playing"
    const vimeoDomain = "https://player.vimeo.com"

    async function getVimeoVideoSize(iframe) {
        return new Promise((resolve) => {
            const widthMessage = JSON.stringify({ method: "getVideoWidth" })
            const heightMessage = JSON.stringify({ method: "getVideoHeight" })
            let width, height

            const handleMessage = (event) => {
                if (event.origin !== vimeoDomain) return

                const data = JSON.parse(event.data)

                if (data.event === "ready") {
                    iframe.contentWindow.postMessage(widthMessage, vimeoDomain)
                    iframe.contentWindow.postMessage(heightMessage, vimeoDomain)
                } else if (data.method === "getVideoWidth") {
                    width = data.value
                    if (height) {
                        window.removeEventListener("message", handleMessage)
                        resolve({ width, height })
                    }
                } else if (data.method === "getVideoHeight") {
                    height = data.value
                    if (width) {
                        window.removeEventListener("message", handleMessage)
                        resolve({ width, height })
                    }
                }
            }

            window.addEventListener("message", handleMessage)
        })
    }

    function getButtonIcon(buttonIcon, isPlaying) {
        const [url, iconName] = buttonIcon.getAttribute("xlink:href").split("#")
        let newIconName

        if (isPlaying) {
            newIconName = iconName.replace("play", "pause")
        } else {
            newIconName = iconName.replace("pause", "play")
        }

        buttonIcon.setAttribute("xlink:href", `${url}#${newIconName}`)
    }

    function controlVideoPlayback(iframe, method) {
        var iframeWindow = iframe.contentWindow
        iframeWindow.postMessage(`{"method": "${method}"}`, vimeoDomain)
    }

    function handlePlayButtonClick(event) {
        const playButtonElement = event.currentTarget
        playButtonElement.classList.toggle(isPlayingClass)
        const startPlaying = playButtonElement.classList.contains(isPlayingClass)
        const videoUrl = playButtonElement.dataset.videoUrl
        const shouldPlayInBackground = playButtonElement.hasAttribute("data-play-in-background")
        const heroMediaBlock = playButtonElement.closest(blockSelector)
        const videoIframe = heroMediaBlock.querySelector("iframe")
        const buttonIcon = playButtonElement.querySelector("svg use")
        const playButtonTextElement = heroMediaBlock.querySelector(playButtonTextSelector)

        if (!videoUrl) return

        if (buttonIcon) {
            getButtonIcon(buttonIcon, startPlaying)
        }

        if (startPlaying) {
            if (playButtonTextElement) {
                playButtonTextElement.innerText = playButtonElement.dataset.stopText
            }

            if (!videoIframe) {
                createVideoPlayer(heroMediaBlock, videoUrl, shouldPlayInBackground)
            } else {
                controlVideoPlayback(videoIframe, "play")
            }
        } else {
            if (playButtonTextElement) {
                playButtonTextElement.innerText = playButtonElement.dataset.playText
            }

            controlVideoPlayback(videoIframe, "pause")
        }
    }

    async function createVideoPlayer(heroMediaBlock, videoUrl, shouldPlayInBackground) {
        const videoMarkup = `
            <iframe src="${videoUrl}?background=${shouldPlayInBackground ? 1 : 0}&autoplay=1"
                class="hero-media-block__video"
                width="1740"
                height="784"
                allow="autoplay"
            >
            </iframe>`

        const mediaWrapperElement = heroMediaBlock.querySelector(mediaWrapperSelector)

        if (!mediaWrapperElement) return

        mediaWrapperElement.insertAdjacentHTML("beforeend", videoMarkup)

        if (heroMediaBlock.classList.contains("hero-media-block--plays-inline")) return

        const iframe = mediaWrapperElement.querySelector("iframe")
        const videoDimensions = await getVimeoVideoSize(iframe)
        const videoRatio = videoDimensions.width / videoDimensions.height
        const containerRatio = heroMediaBlock.offsetWidth / heroMediaBlock.offsetHeight
        const scaleFactor = videoRatio < containerRatio ? containerRatio / videoRatio : videoRatio / containerRatio

        iframe.setAttribute("style", `transform: scale(${scaleFactor})`)

        const resizeObserver = new ResizeObserver((entries) => {
            const { width, height } = entries[0].contentRect
            const newContainerRatio = width / height
            const newScaleFactor = videoRatio < newContainerRatio ? newContainerRatio / videoRatio : videoRatio / newContainerRatio
            iframe.setAttribute("style", `transform: scale(${newScaleFactor})`)
        })

        resizeObserver.observe(heroMediaBlock)
    }

    function init() {
        const heroMediaBlocks = document.querySelectorAll(blockSelector)

        heroMediaBlocks.forEach((heroMediaBlock) => {
            const playButtonElement = heroMediaBlock.querySelector(playButtonSelector)

            if (!playButtonElement) return

            playButtonElement.addEventListener("click", handlePlayButtonClick)
        })
    }

    init()
}

export default heroMediaBlock
