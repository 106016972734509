import React, { Fragment, useEffect } from "react"
import { translate } from "../Services/translation"

const Navigation = ({ links = [], contentLink = null, level = 0, imageLinks = [], topLinks = [], linkedHeadersProp, leftSide }) => {
    let menuRef = React.createRef()

    const additionClass = contentLink && contentLink.attributes ? contentLink.attributes.cssValue : null
    const hasChildrenClass = links.length > 0 ? "has-children" : null
    let linkedHeaders = false

    if (level === 1) {
        linkedHeaders = contentLink.linkedHeaders
    } else if (level < 1) {
        linkedHeaders = linkedHeadersProp
    }

    let shouldStructure = level > 0 && links.filter((x) => x.url).length > 0

    useEffect(() => {
        const handler = (e) => {
            if (!document.getElementById("navbar").contains(e.target)) {
                document.querySelectorAll(".navbar__menu.menu_level_0 > ul > .is-open").forEach((x) => x.classList.remove("is-open"))
            }
        }

        document.addEventListener("mousedown", handler)
        document.addEventListener("touchstart", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
            document.removeEventListener("touchstart", handler)
        }
    }, [])

    const toggleMenu = (e) => {
        menuRef.current.classList.toggle("navbar__menu--show")
        e.currentTarget.classList.toggle("open")
    }

    const handleClick = (target, url) => {
        target.parentElement.childNodes.forEach((x) => x !== target && x.classList.remove("is-open"))
        target.classList.toggle("is-open")

        if (navigator.userAgent.indexOf("MSIE") !== -1 || navigator.appVersion.indexOf("Trident/") > 0) {
            var evt = document.createEvent("UIEvents")
            evt.initUIEvent("resize", true, false, window, 0)
            window.dispatchEvent(evt)
        } else {
            window.dispatchEvent(new Event("resize"))
        }
    }

    return (
        <Fragment>
            {!contentLink ? (
                <button type="button" className="navbar__link--block navbar__icon--menu navbar__icon" onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span className="show-for-sr">{translate("general.menu")}</span>
                </button>
            ) : (
                <Fragment>
                    {contentLink.subLinks.length > 0 ? (
                        <button
                            className={`navbar__link ${hasChildrenClass || ""} ${additionClass || ""} ${`navbar__link--level-${level}`}`}
                            onClick={(event) => handleClick(event.currentTarget.parentElement, contentLink.url)}
                        >
                            {contentLink.name || contentLink.title}
                        </button>
                    ) : contentLink.url ? (
                        <a
                            className={
                                contentLink.linkType === "Button"
                                    ? "btn"
                                    : `navbar__link ${hasChildrenClass || ""} ${additionClass || ""} ${`navbar__link--level-${level}`}`
                            }
                            href={contentLink.url.href || contentLink.url}
                        >
                            {contentLink.name || contentLink.title}
                        </a>
                    ) : (
                        <div
                            className={`navbar__link ${hasChildrenClass || ""} ${additionClass || ""} navbar__custom-heading ${`navbar__link--level-${level}`}`}
                        >
                            {contentLink.name || contentLink.title}
                        </div>
                    )}

                    {links.length > 0 && <i className="navbar__icon--caret-right navbar__icon navbar__icon--open" onClick={toggleMenu}></i>}
                </Fragment>
            )}

            {(links.length > 0 || topLinks.length > 0) && (
                <Fragment>
                    <div className={`navbar__menu menu_level_${level}`} ref={menuRef} test={links.length}>
                        {leftSide && (
                            <article className="navbar__leftside">
                                <img src={leftSide.imageUrl} alt={leftSide.altText} width="470" height="308" className="h-mb-md" />
                                <h2 className="h-font-display-m h-mb-sm">{leftSide.title}</h2>
                                <div dangerouslySetInnerHTML={{ __html: leftSide.text }} className="h-mb-md" />
                                {leftSide.link && (
                                    <a href={leftSide.link.href} className="btn">
                                        {leftSide.linkText}
                                    </a>
                                )}
                            </article>
                        )}
                        <ul>
                            <li className="navbar__menu-header">
                                {contentLink && (
                                    <Fragment>
                                        <span className="navbar__link" onClick={toggleMenu}>
                                           {contentLink.name || contentLink.title}
                                        </span>
                                        <i className="navbar__icon--caret-left navbar__icon" onClick={toggleMenu}></i>
                                    </Fragment>
                                )}
                            </li>
                            {links.length > 0 &&
                                links.map((link, i) =>
                                    !link.name && !link.title ? null : (
                                        <Fragment key={`${link.name || link.title}-${i}`}>
                                            <li
                                                className={
                                                    "navbar__item " +
                                                    `navbar__item-level-${level}` +
                                                    (link.rightSide && !link.megaMenu ? " navbar__item-top-link" : "") +
                                                    (i > 0 && links[i - 1].megaMenu === true ? " navbar--noMarginLeft" : "") +
                                                    (link.isSelected ? " navbar__item--selected" : "") +
                                                    (link.selected && level > 0 ? " is-open" : link.selected && level === 0 ? " is-selected" : "")
                                                }
                                            >
                                                <Navigation
                                                    links={link.subLinks}
                                                    contentLink={link}
                                                    level={level + 1}
                                                    imageLinks={link.imageLinks}
                                                    linkedHeadersProp={linkedHeaders}
                                                    leftSide={link.lefSide}
                                                />
                                            </li>
                                        </Fragment>
                                    )
                                )}
                            {topLinks.length > 0 &&
                                topLinks.map((topLink, i) => {
                                    return (
                                        <Fragment key={`${topLink.text}-${i}`}>
                                            <li className="navbar__item navbar__item-top-link">
                                                <a className="navbar__link" href={topLink.href}>
                                                    {topLink.text}
                                                </a>
                                            </li>
                                        </Fragment>
                                    )
                                })}
                        </ul>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default Navigation
