import { get } from "../Services/http"
import { catchError } from "./Error.action"
import { isNullOrUndefined } from "../utils"

export const MUNICIPALITIES_RECEIVE = "MUNICIPALITIES_RECEIVE"

const rootRoute = `/api/mypagemunicipalities`

export const query = (selectedKeys) => (dispatch) => {
    const url = rootRoute
    return get(url)
        .then((response) => response.json())
        .then((result) => {
            dispatch(receive(result, selectedKeys))
        })
}

export const receive = (res, selectedKeys) => {
    return {
        type: MUNICIPALITIES_RECEIVE,
        payload: {
            items: res,
            selectedItems: !isNullOrUndefined(selectedKeys) ? res.filter((x) => selectedKeys.includes(x.Key)) : [],
        },
    }
}
