import React, { Component, Fragment } from "react"
import onClickOutside from "react-onclickoutside"
import { isNullOrUndefined } from "../../utils"

class ProductDropdown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuOpen: false,
            filters: {},
        }
    }

    handleClickOutside(evt) {
        if (this.state.menuOpen) {
            this.setState({ menuOpen: false })
        }
    }

    toggleMenu(currentState) {
        this.setState({ menuOpen: !currentState })
    }

    hasItem(item) {
        return this.props.values.some((x) => x == item[this.props.valueField])
    }

    handleSelected(item) {
        if (this.props.isMultiSelect && !this.props.shouldRedirect) {
            if (!this.hasItem(item)) {
                this.addSelected(item)
            } else {
                this.removeSelected(item)
            }
        } else {
            if (!this.props.shouldRedirect) {
                if (!this.hasItem(item)) {
                    this.setSelected(item)
                } else {
                    this.removeSelected(item)
                }
            } else {
                if (this.props.onClick !== undefined) {
                    this.props.onClick(item)
                }
            }
        }
    }

    addSelected(item) {
        var newState = [...this.props.values, item]
        this.props.onChange(item)
    }

    removeSelected(item) {
        var newState = this.props.values.filter((x) => x[this.props.valueField] != item[this.props.valueField])
        this.props.onChange(item)
    }

    setSelected(item) {
        var newState = this.props.values.filter((x) => x[this.props.valueField] != item[this.props.valueField])
        this.props.onChange(item)
        //this.props.onChange([...newState, item]);
    }

    render() {
        const { options, values, isMultiSelect, shouldRedirect, labelField, valueField, label } = this.props
        const { menuOpen } = this.state

        return (
            <Fragment>
                <div className="dropdown__menu--container" style={{ position: "relative" }}>
                    <div className="dropdown__menu-parent" onClick={() => this.toggleMenu(menuOpen)}>
                        {label && <p className="dropdown__button-label">{label}</p>}
                        <button type="button" className={`dropdown__button ${menuOpen ? "active" : ""}`}></button>
                    </div>
                    {menuOpen && (
                        <div className="dropdown__menu" style={{ backgroundColor: "white", position: "absolute", zindex: "2" }}>
                            {options &&
                                options.map((item) => (
                                    <div
                                        className="dropdown__menu--item"
                                        key={!isNullOrUndefined(valueField) ? item[valueField] : item["index"]}
                                        onClick={() => this.handleSelected(item)}
                                        style={{
                                            minWidth: "240px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            padding: "8px 12px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <div className={`dropdown__menu--label ${!shouldRedirect && this.hasItem(item) ? "selected" : ""}`}>
                                            {!isNullOrUndefined(labelField) ? item[labelField] : item["name"]}
                                        </div>
                                        {shouldRedirect == false && (
                                            <Fragment>
                                                <input readOnly type="checkbox" className="dropdown__menu--checkbox" checked={this.hasItem(item)} />
                                                <span className={`dropdown__menu--tick ${this.hasItem(item) ? "checked" : ""}`}></span>
                                            </Fragment>
                                        )}
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            </Fragment>
        )
    }
}

export default onClickOutside(ProductDropdown)
