import React from "react"

const validSizes = ["xs", "sm", "md", "lg", "xl"]

const Icon = ({ name, size = "md", type = "fill", isSiteSpecific }) => {
    const iconClasses = `icon icon--${type} icon--size-${size}`
    const iconUrl = `/ui/svg/sprite.svg#icon-${isSiteSpecific ? `${window.__litium.siteContext.toLowerCase().replace(/[0-9]/g, "")}-${name}` : name}`

    let style = {}

    if (!validSizes.includes(size) && parseInt(size)) {
        const customSize = parseInt(size)
        style = { width: `${customSize}px`, height: `${customSize}px` }
    }

    return (
        <svg className={iconClasses} aria-hidden="true" style={style}>
            <use xlinkHref={iconUrl}></use>
        </svg>
    )
}

export default Icon
