import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import FacetedSearchCompact from "../Components/FacetedSearchCompact"
import { AccordionPanel, Accordion } from "./Accordion.container"
import { query, submitSearchFacet, toggleVisibleDropdownMenu, searchFacetChange } from "../Actions/FacetedSearch.action"
import SubNav from "../Components/SubNavCompact"
import SortCriteriaCompact from "../Components/SortCriteriaCompact"
import { translate } from "../Services/translation"
import { PRODUCT_VIEW_CACHED, updateFilterOption } from "../Actions/FacetedSearch.action"

class FacetedSearchCompactContainer extends Component {
    constructor(props) {
        super(props)
        this.currentProductsViewCachedId = null
        const { facetFilters } = props
        const filterString = JSON.stringify(facetFilters)
        this.state = {
            facetFilters,
            filterString,
        }
    }

    componentDidMount() {
        this.props.query(window.location.search.substr(1) || "", false)
    }

    onFacetChange(filter, option) {
        this.setState((prevState) => {
            const { facetFilters } = this.state
            const newFilters = updateFilterOption(facetFilters, filter, option)
            return {
                ...prevState,
                facetFilters: newFilters,
            }
        })
    }

    static getDerivedStateFromProps(nextProp, prevState) {
        const { facetFilters } = nextProp
        const filterString = JSON.stringify(facetFilters)
        if (filterString !== prevState.filterString) {
            return {
                ...prevState,
                facetFilters,
                filterString,
            }
        }
        return prevState
    }

    onSearchResultDataChange(dom) {
        if ([null, undefined].includes(dom)) {
            return
        }
        const container = document.createElement("div")
        container.innerHTML = dom
        const existingResult = document.querySelector("#search-result")
        const tempResult = container.querySelector("#search-result")
        const existingFilter = existingResult.querySelector("#facetedSearchCompact")
        const tempFilter = tempResult.querySelector("#facetedSearchCompact")
        const replace = (node, newNode) => node.parentNode.replaceChild(newNode, node)

        if (this.props.pageNumber > 1) {
            var newItems = tempResult.getElementsByClassName("product-list__item")
            var list = existingResult.querySelector("ul.product-list")
            Array.from(newItems).forEach((item) => {
                list.appendChild(item)
            })
        } else {
            // move existingFilter from existingResult to tempResult
            replace(tempFilter, existingFilter)
            // replace existingResult with tempResult ( newResult )
            replace(existingResult, tempResult)
        }

        if (!tempResult.querySelector("#search-result-load-more")) {
            if (existingResult.querySelector("#search-result-load-more")) {
                existingResult.querySelector("#search-result-load-more").remove()
            }
        }

        // bootstrap react components if any exists in the search result
        window.__litium.bootstrapComponents()
    }

    componentDidUpdate() {
        const { productsViewCachedId } = this.props
        if (productsViewCachedId && this.currentProductsViewCachedId !== productsViewCachedId) {
            this.currentProductsViewCachedId = productsViewCachedId
            const productViewCached = window.__litium.cache ? window.__litium.cache[PRODUCT_VIEW_CACHED] || {} : {}
            if (!productViewCached || productViewCached.productsViewCachedId !== this.currentProductsViewCachedId) {
                return
            }
            const dom = productViewCached.productsView
            dom && this.onSearchResultDataChange(dom)
        }
    }

    render() {
        const { subNavigation = [], sortCriteria = [], navigationTheme = "", ...facetProps } = this.props
        const { facetFilters } = this.state
        const facetState = {
            ...facetProps,
            facetFilters,
            onFacetChange: (filter, option) => this.onFacetChange(filter, option),
        }
        const hidden = [subNavigation, facetFilters, sortCriteria].every((ele) => !ele || ele.length === 0)
        return hidden ? null : (
            <Fragment>
                <Accordion className="compact-filter hide-for-large">
                    {subNavigation.length > 0 && false && (
                        <AccordionPanel header={translate("facet.header.categories")}>
                            <SubNav {...{ subNavigation }} />
                        </AccordionPanel>
                    )}
                    {facetFilters.length > 0 && (
                        <AccordionPanel header={translate("facet.header.filter")}>
                            <FacetedSearchCompact {...facetState} />
                        </AccordionPanel>
                    )}
                    {sortCriteria.length > 0 && false && (
                        <AccordionPanel header={translate("facet.header.sortCriteria")}>
                            <SortCriteriaCompact {...{ sortCriteria }} />
                        </AccordionPanel>
                    )}
                </Accordion>
                {/*<FilterTag {...{...facetProps, navigationTheme}} />*/}
                {navigationTheme === "category" && (
                    <div className="compact-filter category-theme show-for-large">
                        <FacetedSearchCompact {...facetState} />
                    </div>
                )}
            </Fragment>
        )
    }
}

const mapStateToProps = ({
    facetedSearch: { subNavigation, sortCriteria, facetFilters, visibleDropdownMenu, navigationTheme, productsViewCachedId, pageNumber },
}) => {
    return {
        subNavigation,
        sortCriteria,
        facetFilters,
        visibleDropdownMenu,
        navigationTheme,
        productsViewCachedId,
        pageNumber,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        query: (q, withHtmlResult) => dispatch(query(q, withHtmlResult)),
        onSubmit: (allFilters) => dispatch(submitSearchFacet(allFilters)),
        toggleVisibleDropdownMenu: (group) => dispatch(toggleVisibleDropdownMenu(group)),
        searchFacetChange: (group, item) => dispatch(searchFacetChange(group, item)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacetedSearchCompactContainer)
