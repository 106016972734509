import { CSSTransitionGroup } from "react-transition-group"
import React, { Component, Fragment } from "react"

class NwpAccordion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: props.visible,
            label: "",
        }
    }

    setVisibility(value) {
        this.setState({ visible: value })
    }

    render() {
        const { visible } = this.state
        const handleOnClick = () => this.setVisibility(!visible)

        return (
            <Fragment>
                <div className={`row pointer accordion-component accordion-component--product`}>
                    <div className="columns small-12">
                        <button type="button" className="accordion-container__title-container show-toggle-button" onClick={handleOnClick}>
                            <h2>{this.state.label ? this.state.label : this.props.label}</h2>
                        </button>
                    </div>
                </div>

                <div className={`row toggleable ${visible ? "show" : ""}`}>{this.props.children}</div>
            </Fragment>
        )
    }
}

export default NwpAccordion
