import { get, post } from "../Services/http"
import { catchError } from "./Error.action"

export const TREBOLITCALCULATOR_LOGIN = "TREBOLITCALCULATOR_LOGIN"
export const TREBOLITCALCULATOR_LOGIN_ERROR = "TREBOLITCALCULATOR_LOGIN_ERROR"
export const TREBOLITCALCULATOR_LOGIN_MESSAGE = "TREBOLITCALCULATOR_LOGIN_MESSAGE"

export const TREBOLITCALCULATOR_SET_CURRENTUSER = "TREBOLITCALCULATOR_SET_CURRENTUSER"

export const TREBOLITCALCULATOR_SWITCH_STATE = "TREBOLITCALCULATOR_SWITCH_STATE"

export const login = (username, password, successState, errorMessage) => (dispatch, getState) => {
    let model = { username: username, password: password }

    return post("/api/trebolitcalculatorlogin/login", model)
        .then((response) => response.json())
        .then((data) => {
            if (data) {
                dispatch(tryLogin(data))
                dispatch(switchState(successState))
            } else {
                dispatch(setLoginMessage(errorMessage))
            }
        })
        .catch((ex) => dispatch(catchError(ex, (error) => loginError(error))))
}

export const getCurrentUser = (failedState, loginState) => (dispatch, getState) => {
    return get("/api/trebolitcalculatorlogin/getcurrentuser")
        .then((response) => response.json())
        .then((data) => {
            if (data) {
                dispatch(setCurrentUser(data))
                dispatch(switchState(loginState))
            } else {
                dispatch(switchState(failedState))
            }
        })
}

export const logout = (logoutState) => (dispatch) => {
    return get("/api/trebolitcalculatorlogin/logout")
        .then((response) => response.json())
        .then((data) => {
            if (data) {
                dispatch(switchState(logoutState))
                dispatch(setCurrentUser(null))
            }
        })
}

export const setLoginMessage = (message) => ({
    type: TREBOLITCALCULATOR_LOGIN_MESSAGE,
    payload: {
        loginMessage: message,
    },
})

export const setCurrentUser = (currentUser) => ({
    type: TREBOLITCALCULATOR_SET_CURRENTUSER,
    payload: {
        currentUser,
    },
})

export const switchState = (newState) => ({
    type: TREBOLITCALCULATOR_SWITCH_STATE,
    payload: {
        currentState: newState,
    },
})

export const loginError = (error) => ({
    type: TREBOLITCALCULATOR_LOGIN_ERROR,
    payload: {
        error,
    },
})

export const tryLogin = (user) => ({
    type: TREBOLITCALCULATOR_LOGIN,
    payload: {
        currentUser: user,
    },
})
