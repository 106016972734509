import React, { Component } from "react"
import { translate } from "../Services/translation"
import NumericUpDown from "./NumericUpDown"

class CartProductViewer extends Component {
    constructor(props) {
        super(props)
    }

    clamp(num, min, max) {
        return num <= min ? min : num >= max ? max : num
    }

    componentDidMount() {
        /*const visible = this.refs.list.getBoundingClientRect().height !== 0;
        const tooMuchItem = this.props.group.options.length > this.state.showLessItemCount;
        visible && tooMuchItem && this.toggleShowMore();*/
    }

    render() {
        const { orderRow, updateCart } = this.props
        const close = () => updateCart(orderRow.articleNumber, 0)

        const squareMetersByQuantity = (Number(orderRow.quantity) * Number(orderRow.amountPerSquareMeter)).toFixed(2).toString().replace(".", ",")

        var firstAdditionalInfo = orderRow.variantValue ? (
            <div className="cart__product-info">
                <p>
                    <strong>{`${orderRow.variantName ? `${orderRow.variantName}: ` : ""}${orderRow.variantValue}`}</strong>
                </p>
            </div>
        ) : !(orderRow.amountPerSquareMeter > 0 && orderRow.unitOfMeasurement) ? (
            <div className="cart__product-info">
                <p>{`${orderRow.quantity} ${orderRow.unitOfMeasurement}`}</p>
            </div>
        ) : (
                    <div></div>
                )

        return (
            <div className="cart__product-container">
                <div className="cart__product-image-container">
                    {orderRow.image && (
                        <a href={orderRow.url}>
                            <img src={orderRow.image} alt={orderRow.name} />
                        </a>
                    )}
                </div>
                <div className="cart__product-body">
                    <div className="cart__product-header">
                        <div className="cart__product-name">
                            <a href={orderRow.url}>{orderRow.name}</a>
                        </div>
                        {!orderRow.disableQuantityUpdate &&
                            <button type="button" className="cart__product-close" onClick={close}></button>}
                    </div>
                    <div className="cart__product-info-container">
                        {firstAdditionalInfo}

                        {orderRow.amountPerPallet > 0 && orderRow.unitOfMeasurement && (
                            <div className="cart__product-info">
                                <p>{`${translate("checkout.order.amountperpallet").format(orderRow.amountPerPallet, orderRow.unitOfMeasurement)}`}</p>
                            </div>
                        )}
                        {orderRow.amountPerPallet > 0 && orderRow.numberOfPallets > 0 && (
                            <div className="cart__product-info">
                                <p>{`${translate("checkout.order.numberofpallet").format(orderRow.numberOfPallets)}`}</p>
                            </div>
                        )}

                        {orderRow.amountPerSquareMeter > 0 && orderRow.unitOfMeasurement && (
                            <div className="cart__product-info">
                                <p>{`${translate("checkout.order.amountpersquaremeter").format(
                                    orderRow.quantity,
                                    orderRow.unitOfMeasurement,
                                    squareMetersByQuantity
                                )}`}</p>
                            </div>
                        )}

                        {orderRow.needToOrder && (
                            <div className="cart__product-info separator">
                                <p>{translate("checkout.order.needtoorder")}</p>
                            </div>
                        )}
                    </div>
                </div>
                    <div className="cart__product-footer flex-container align-justify align-middle">
                    {!orderRow.disableQuantityUpdate ? (
                        orderRow.isFreeGift ? (
                        <div>{orderRow.quantity}</div>
                        ) : (
                            <NumericUpDown
                                minValue="0"
                                maxValue={Number.MAX_SAFE_INTEGER}
                                quantity={orderRow.quantity}
                                multiple={orderRow.multiple}
                                decimals={orderRow.multipleDecimals || 0}
                                initialValue={orderRow.multiple > 0 ? orderRow.multiple : 1}
                                onChange={(value) => updateCart(orderRow.articleNumber, value)}
                                onlyFullPackage={orderRow.onlyFullPackage ? "true" : "false"}
                            />
                        ))
                        : <div></div>}
                    <div className="cart__product-price">
                        {orderRow.discountPricePerUnit > 0 && orderRow.discountPricePerUnit && (
                            <div>
                                <div className="cart__product-price-discounted">
                                    {orderRow.discountPricePerUnit * orderRow.quantity} {orderRow.currencySymbol}
                                </div>
                                <span className="cart__product-price-original">({orderRow.totalPrice})</span>
                            </div>
                        )}
                        {!orderRow.discountPricePerUnit > 0 && !orderRow.discountPricePerUnit && (
                            <div>
                                <span className="cart__product-price-original">{orderRow.totalPrice}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default CartProductViewer
