import React, { Component, Fragment } from "react"
import { translate } from "../../Services/translation"
import { CSSTransitionGroup } from "react-transition-group"

class ProductFilterList extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { facetFilters, searchFacetChange } = this.props

        const filterOptions = facetFilters.map((group, groupIndex) => {
            return group.selectedOptions.map((filter, filterIndex) => {
                let associatedOption = group.options.filter((x) => x.id == filter)
                let label = associatedOption && associatedOption.length > 0 ? associatedOption[0].label : filter

                return (
                    <Fragment key={groupIndex + filterIndex}>
                        <li className="product__filter" style={{ listStyle: "none" }}>
                            <div className="product__filter-item" onClick={(event) => searchFacetChange(group, associatedOption[0])}>
                                <p className="product__filter-label">{`${group.label} - ${label}`}</p>
                                <div className="product__filter-close"></div>
                            </div>
                        </li>
                    </Fragment>
                )
            })
        })

        return (
            <Fragment>
                <div className="product__filter-container">
                    {facetFilters && facetFilters.some((x) => x.selectedOptions && x.selectedOptions.length > 0) && <p>{translate("search.yourfilter")}</p>}
                    <ul className="product__filter-list">
                        <CSSTransitionGroup
                            component={React.Fragment}
                            transitionName="fade-in-no-transform"
                            transitionEnterTimeout={300}
                            transitionLeaveTimeout={100}
                        >
                            {filterOptions}
                        </CSSTransitionGroup>
                    </ul>
                </div>
            </Fragment>
        )
    }
}

export default ProductFilterList
