import React, { Component } from "react"
import { connect } from "react-redux"
import FacetedSearch from "../Components/FacetedSearch"
import { query, searchFacetChange, toggleVisibility, loadMoreProducts } from "../Actions/FacetedSearch.action"

class FacetedSearchContainer extends Component {
    constructor(props) {
        super(props)

        setTimeout(() => {
            document.cookie = `pageNumber=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
            document.cookie = `pageUrl=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
        }, 10000)

        let isScrolling
        const searchResultElem = document.getElementById("search-result")

        window.addEventListener("scroll", (e) => {
            clearTimeout(isScrolling)
            isScrolling = setTimeout(() => {
                const windowPosition = window.pageYOffset + window.innerHeight - 300
                const searchElementPosition = searchResultElem.offsetTop + searchResultElem.offsetHeight
                if (windowPosition > searchElementPosition) {
                    if (document.getElementById("search-result-load-more")) {
                        window.__litium.events.onLoadMoreProducts()
                    }
                }
            }, 20)
        })
    }

    loadMore() {
        window.__litium.events.onLoadMoreProducts()
    }

    render() {
        return <FacetedSearch {...this.props} />
    }
}

const mapStateToProps = ({ facetedSearch: { facetFilters, navigationTheme, visibility, pageNumber } }) => {
    return {
        facetFilters,
        navigationTheme,
        visibility,
        pageNumber,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        query: (q, replaceResult) => dispatch(query(q, replaceResult)),
        searchFacetChange: (group, item) => dispatch(searchFacetChange(group, item)),
        toggleVisibility: (value) => dispatch(toggleVisibility(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacetedSearchContainer)
