export const ADD_SELECTED_PRODUCTS = "ADD_SELECTED_PRODUCTS"
export const ADD_SELECTED_DOCUMENTS = "ADD_SELECTED_DOCUMENTS"

export const addSelectedProducts = (products) => {
    return {
        type: ADD_SELECTED_PRODUCTS,
        payload: products,
    }
}

export const addSelectedDocuments = (documents) => {
    return {
        type: ADD_SELECTED_DOCUMENTS,
        payload: documents,
    }
}
