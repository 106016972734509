import { get } from "../Services/http"
import { catchError } from "./Error.action"

export const RETAILERS_GET_ITEMS = "RETAILERS_GET_ITEMS"

export const RETAILERS_CHANGE_ITEMS_PER_PAGE = "RETAILERS_CHANGE_ITEMS_PER_PAGE"
export const RETAILERS_CHANGE_TYPE = "RETAILERS_CHANGE_TYPE"
export const RETAILERS_SET_ORDERBY = "RETAILERS_SET_ORDERBY"
export const RETAILERS_SEARCH = "RETAILERS_SEARCH"
export const RETAILERS_CITY_LOOKUP = "RETAILERS_CITY_LOOKUP"
export const RETAILERS_SHOW_ON_MAP = "RETAILERS_SHOW_ON_MAP"

export const RETAILERS_NEXT_PAGE = "RETAILERS_NEXT_PAGE"
export const RETAILERS_PREV_PAGE = "RETAILERS_PREV_PAGE"
export const RETAILERS_GO_TO_PAGE = "RETAILERS_GO_TO_PAGE"

export const RETAILERS_REFRESH_ITEMS = "RETAILERS_REFRESH_ITEMS"
export const RETAILERS_RECEIVE = "RETAILERS_RECEIVE"
export const RETAILERS_CITY_RECEIVE = "RETAILERS_CITY_RECEIVE"
export const RETAILERS_REFRESH_MARKERS = "RETAILERS_REFRESH_MARKERS"

const websiteId = window.location.href.includes("mataki") ? "Mataki" : "Trebolit"
const rootRoute = `/api/retailers?websiteId=${websiteId}`
const cityRoute = `/api/retailers/citylookup`

export const query = (type) => (dispatch) => {
    const url = type ? `${rootRoute}&type=${type}` : rootRoute
    return get(url)
        .then((response) => response.json())
        .then((result) => {
            dispatch(receive(result))
        })
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))
}

export const cityQuery = (city) => (dispatch) => {
    if (city) {
        const url = city ? `${cityRoute}?city=${city}` : cityRoute
        return get(url)
            .then((response) => response.json())
            .then((result) => {
                dispatch(cityReceive(city, result))
            })
            .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))
    }
}

export const setItemsPerPage = (itemsPerPage) => {
    sessionStorage.setItem("retailers_itemsPerPage", itemsPerPage.toString())
    return {
        type: RETAILERS_CHANGE_ITEMS_PER_PAGE,
        payload: {
            startIndex: 0,
            itemsPerPage: parseInt(itemsPerPage),
        },
    }
}

export const setTypeFilter = (type) => ({
    type: RETAILERS_CHANGE_TYPE,
    payload: {
        type,
        startIndex: 0,
    },
})

export const setSearchQuery = (searchQuery) => ({
    type: RETAILERS_SEARCH,
    payload: {
        searchQuery,
        startIndex: 0,
    },
})

export const setOrderBy = (orderBy) => {
    sessionStorage.setItem("retailers_orderBy", orderBy)
    return {
        type: RETAILERS_SET_ORDERBY,
        payload: {
            orderBy,
            startIndex: 0,
        },
    }
}

export const showOnMap = (retailer, activeMarker) => ({
    type: RETAILERS_SHOW_ON_MAP,
    payload: {
        activeMarker,
        latitude: retailer.latitude,
        longitude: retailer.longitude,
        city: retailer.city,
    },
})

export const nextPage = (startIndex, itemsPerPage) => ({
    type: RETAILERS_NEXT_PAGE,
    payload: {
        startIndex: Math.max(0, startIndex + itemsPerPage),
    },
})

export const prevPage = (startIndex, itemsPerPage) => ({
    type: RETAILERS_PREV_PAGE,
    payload: {
        startIndex: Math.max(0, startIndex - itemsPerPage),
    },
})

export const goToPage = (page, itemsPerPage) => ({
    type: RETAILERS_GO_TO_PAGE,
    payload: {
        startIndex: Math.max(0, (page - 1) * itemsPerPage),
    },
})

export const receive = (res) => {
    var sourceItems = []
    var categories = []

    res.forEach((e) => {
        sourceItems = sourceItems.concat(e.retailers.filter((r) => !isNaN(r.latitude) && !isNaN(r.longitude)))
        categories.push({
            id: e.id,
            name: e.name,
        })
    })

    return {
        type: RETAILERS_RECEIVE,
        payload: {
            startIndex: 0,
            sourceItems,
            categories,
            items: [],
            loading: false,
        },
    }
}

export const cityReceive = (city, res) => {
    return {
        type: RETAILERS_CITY_RECEIVE,
        payload: {
            longitude: res.longitude,
            latitude: res.latitude,
            zoom: 12,
            city,
            orderBy: "distance",
        },
    }
}

export const refreshItems = (items) => ({
    type: RETAILERS_REFRESH_ITEMS,
    payload: {
        items,
        startIndex: 0,
    },
})

export const refreshMarkers = (markerObjects) => ({
    type: RETAILERS_REFRESH_MARKERS,
    payload: {
        markerObjects,
    },
})

export const setError = (error) => {
    alert(error.message || error)
    console.error(error)
}
