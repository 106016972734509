import { get, post, put } from "../Services/http"
import { catchError } from "./Error.action"
import { show as showNotification } from "./Notification.action"

export const CART_LOAD = "CART_LOAD"
export const CART_LOAD_ERROR = "CART_LOAD_ERROR"
export const CART_RECEIVE = "CART_RECEIVE"
export const CART_SHOW_INFO = "CART_SHOW_INFO"
export const CART_HIDE = "CART_HIDE"
export const CART_USES_MOCK = "CART_USES_MOCK"

export const load = () => (dispatch, getState) => {
    return get("/api/cart")
        .then((response) => response.json())
        .then((cart) => dispatch(receive(cart)))
        .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))))
}

export const refresh = () => (dispatch, getState) => {
    return get("/api/cart/refresh")
        .then((response) => response.json())
        .then((cart) => dispatch(receive(cart)))
        .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))))
}

export const loadError = (error) => ({
    type: CART_LOAD_ERROR,
    payload: {
        error,
    },
})

export const receive = (cart) => {
    sessionStorage.setItem("cartQuantity", cart.quantity)
    return {
        type: CART_RECEIVE,
        payload: cart,
    }
}

export const toggle = () => (dispatch, getState) => {
    dispatch(show(!getState().cart.showInfo))
}

const show = (visible) => ({
    type: CART_SHOW_INFO,
    payload: {
        showInfo: visible,
    },
})

export const setUsesMock = (value) => (dispatch, getState) => {
    dispatch(usesMock(value))
}

const usesMock = (value) => ({
    type: CART_USES_MOCK,
    payload: {
        usesMockAddress: value,
    },
})

export const add = ({ articleNumber, quantity = 1, nodeIdToShowNotification = null, notificationMessage = null, hash = "" }) => {
    return (dispatch) => {
        if (isNaN(quantity) || parseInt(quantity) < 0) {
            return
        }

        return post("/api/cart/add", { articleNumber, quantity: quantity })
            .then((response) => response.json())
            .then((cart) => {
                dispatch(receive(cart))
                nodeIdToShowNotification && dispatch(showNotification(nodeIdToShowNotification, notificationMessage, hash))
            })
            .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))))
    }
}

export const reorder = ({ orderid }) => {
    if (orderid) {
        return (dispatch) => {
            return post("/api/cart/reorder", { orderid })
                .then((response) => response.json())
                .then((cart) => dispatch(receive(cart)))
                .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))))
        }
    }
}

export const update = (articleNumber, quantity) => (dispatch) => {
    return put(`/api/cart/update`, { articleNumber, quantity })
        .then((response) => response.json())
        .then((cart) => dispatch(receive(cart)))
        .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))))
}
