import { combineReducers } from "redux"
import { cart } from "./Reducers/Cart.reducer"
import { quickSearch } from "./Reducers/QuickSearch.reducer"
import { navigation } from "./Reducers/Navigation.reducer"
import { menu } from "./Reducers/Menu.reducer"
import { facetedSearch } from "./Reducers/FacetedSearch.reducer"
import { myPage } from "./Reducers/MyPage.reducer"
import { checkout } from "./Reducers/Checkout.reducer"
import { lightboxImages } from "./Reducers/LightboxImages.reducer"
import { notification } from "./Reducers/Notification.reducer"
import { calculator } from "./Reducers/NwpCalculator.reducer"
import { retailers } from "./Reducers/Retailers.reducer"
import { municipalities } from "./Reducers/Municipalites.reducer"
import { trebolitCalculator } from "./Reducers/TrebolitCalculator.reducer"
import { documents } from "./Reducers/Documents.reducer"
import { downloadDocuments } from "./Reducers/DownloadDocuments.reducer"

const app = combineReducers({
    cart,
    checkout,
    quickSearch,
    navigation,
    menu,
    myPage,
    facetedSearch,
    lightboxImages,
    notification,
    calculator,
    retailers,
    municipalities,
    trebolitCalculator,
    documents,
    downloadDocuments,
})

export default app
