import { saveAs } from "file-saver"
import { get, post } from "../../../Services/http"

export const NWP_CALCULATOR_LOAD = "NWP_CALCULATOR_LOAD"
export const NWP_CALCULATOR_LOAD_ERROR = "NWP_CALCULATOR_LOAD_ERROR"
export const NWP_CALCULATOR_RECEIVE = "NWP_CALCULATOR_RECEIVE"

export const NWP_CALCULATOR_SETVAR = "NWP_CALCULATOR_SETVAR"

export const NWP_CALCULATOR_INCREASEROOFAREACOUNT = "NWP_CALCULATOR_INCREASEROOFAREACOUNT"
export const NWP_CALCULATOR_DECREASEROOFAREACOUNT = "NWP_CALCULATOR_DECREASEROOFAREACOUNT"

export const NWP_CALCULATOR_ROOFAREASRECIEVE = "NWP_CALCULATOR_ROOFAREASRECIEVE"

export const download = (fileName, objectName) => {
    return get("/api/calculator/download?fileName=" + fileName)
        .then((response) => response.blob())
        .then((blob) => saveAs(blob, objectName ? `Avvattningsdimensionering - ${objectName}.pdf` : "Avvattningsdimensionering.pdf"))
}

export const calculate = (event, index) => async (dispatch, getState) => {
    if (event) {
        event.preventDefault()
    }

    let data = getState().calculator

    let roofArea = data.selectedRoofAreas[index]

    if (roofArea && roofArea.complete) {
        if (!data.selectedMunicipality || !data.selectedSurfaceCoefficient) {
            return
        }

        let mappedFlowCapacities = data.flowCapacities.filter((x) => x.diameter === roofArea.drainDiameter.value)

        if (mappedFlowCapacities) {
            let body = {
                index: index,

                issuedTo: data.selectedIssuedTo,
                objectName: data.selectedObjectName,
                roofer: data.selectedRoofer,

                time: data.selectedTime ? Number(data.selectedTime.value.time) : 10,
                years: data.selectedYear ? Number(data.selectedYear.value.year) : 5,
                municipality: data.selectedMunicipality.value,
                riskFactor: data.selectedRiskFactor ? Number(data.selectedRiskFactor.value.value) : data.riskFactors[0].value,
                surfaceCoefficient: Number(data.selectedSurfaceCoefficient.value.value),
                mainArea: Number(roofArea.roofArea),
                offArea: Number(roofArea.extraRoofArea),
                drainValues: mappedFlowCapacities.map((x) => Number(x.value)),
            }

            return post("/api/calculator/calculate", body)
                .then((response) => response.json())
                .then(async (data) => {
                    let roofArea = await getState().calculator.selectedRoofAreas[data.index]
                    if (roofArea) {
                        roofArea.resultValues = data.values
                        dispatch(setVariable("resultValues", data.values))
                        dispatch(mutateRoofArea(data.index, roofArea, false))
                    }
                })
        }
    }
}

export const requestPdf = (event, company) => (dispatch, getState) => {
    if (event) {
        event.preventDefault()
    }

    let data = getState().calculator
    let roofAreas = data.selectedRoofAreas.filter((x) => x && x.selectedDrainageType > 0 && x.complete)

    if (roofAreas.length > 0 && data.selectedMunicipality && data.selectedSurfaceCoefficient) {
        let generalBody = {
            company: company,
            issuedTo: data.selectedIssuedTo,
            objectName: data.selectedObjectName,
            roofer: data.selectedRoofer,
            madeBy: data.selectedMadeBy,

            time: data.selectedTime ? Number(data.selectedTime.value.time) : 10,
            years: data.selectedYear ? Number(data.selectedYear.value.year) : 5,
            municipality: data.selectedMunicipality.value,
            riskFactor: data.selectedRiskFactor ? Number(data.selectedRiskFactor.value.value) : data.riskFactors[0].value,
            surfaceCoefficient: Number(data.selectedSurfaceCoefficient.value.value),
        }

        let mappedRoofAreas = roofAreas.map((roofArea) => {
            let mappedFlowCapacity = data.flowCapacities.filter(
                (x) => x.diameter === roofArea.drainDiameter.value && x.type === roofArea.selectedDrainageType
            )[0]

            if (mappedFlowCapacity) {
                var realIndex = data.selectedRoofAreas.indexOf(roofArea)

                return {
                    index: realIndex,
                    name: roofArea.name ? roofArea.name : "Takyta #" + Number(realIndex + 1),
                    mainArea: Number(roofArea.roofArea),
                    offArea: Number(roofArea.extraRoofArea),
                    flowCapacity: mappedFlowCapacity,
                }
            }

            return null
        })

        let body = { ...generalBody, mappedRoofing: mappedRoofAreas }

        return post("/api/calculator/createpdf", body)
            .then((response) => response.text())
            .then((data) => download(data, body.objectName))
    }
}

export const load = (company, blockId) => (dispatch) => {
    const calculatorEndpoint = blockId
        ? `/api/calculator/getcalculatorblockdata?company=${company}&blockId=${blockId}`
        : `/api/calculator/getcalculatordata?company=${company}`

    return get(calculatorEndpoint)
        .then((response) => response.json())
        .then((data) => {
            dispatch(receive(data))
        })
}

export const loadError = (error) => ({
    type: NWP_CALCULATOR_LOAD_ERROR,
    payload: {
        error,
    },
})

export const receive = (data) => ({
    type: NWP_CALCULATOR_RECEIVE,
    payload: data,
})

export const setVariable = (variable, data) => ({
    type: NWP_CALCULATOR_SETVAR,
    payload: {
        [variable]: data,
    },
})

export const roofAreasRecieve = (roofAreas) => ({
    type: NWP_CALCULATOR_ROOFAREASRECIEVE,
    payload: {
        selectedRoofAreas: roofAreas,
    },
})

export const increaseRoofAreaCount = () => (dispatch, getState) => {
    let roofAreas = getState().calculator.selectedRoofAreas
    if (roofAreas.length + 1 <= 5) {
        roofAreas.push({})
        dispatch(setVariable("resultAreas", roofAreas))
        dispatch(roofAreasRecieve(roofAreas))
    }
}

export const decreaseRoofAreaCount = () => (dispatch, getState) => {
    let roofAreas = getState().calculator.selectedRoofAreas
    if (roofAreas.length > 1) {
        roofAreas.splice(roofAreas.length - 1, 1)
        dispatch(setVariable("resultAreas", roofAreas))
        dispatch(roofAreasRecieve(roofAreas))
    }
}

export const removeRoofArea = (roofArea) => (dispatch, getState) => {
    let roofAreas = getState().calculator.selectedRoofAreas
    let index = roofAreas.indexof(roofArea)

    if (roofAreas.length > 1 && index > -1) {
        roofAreas.splice(index, 1)
        dispatch(setVariable("resultAreas", roofAreas))
        dispatch(roofAreasRecieve(roofAreas))
    }
}

export const mutateRoofArea = (index, value, callCalculate = true) => async (dispatch, getState) => {
    let roofAreas = await getState().calculator.selectedRoofAreas

    if (index <= roofAreas.length - 1) {
        roofAreas[index] = value
        await dispatch(roofAreasRecieve(roofAreas))

        if (callCalculate) {
            dispatch(calculate(null, index))
        }
    }
}
