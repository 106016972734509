import { CSSTransitionGroup } from "react-transition-group"
import React, { Component, Fragment } from "react"
import { translate } from "../../../../Services/translation"

import cylinderImage from "../../../../../../images/Calculator/a.png"
import lockableImage from "../../../../../../images/Calculator/b.png"
import monsoonImage from "../../../../../../images/Calculator/c.png"
import { connect } from "react-redux"

const mappedDrainageTypes = [
    { id: 1, name: translate("calculator.ceilingarea.drainagetype.cylinder"), image: cylinderImage },
    { id: 2, name: translate("calculator.ceilingarea.drainagetype.lockable"), image: lockableImage },
    { id: 3, name: translate("calculator.ceilingarea.drainagetype.monsoon"), image: monsoonImage },
]

class NwpDrainageDisplay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: undefined,
        }
    }

    onValueChanged(prop, value) {
        let newValue = this.state.selected === value ? undefined : value
        this.setState({ [prop]: newValue })

        if (this.props.onChange) {
            this.props.onChange(newValue)
        }
    }

    render() {
        const { options, resultValues, mappedOption, productLink } = this.props
        const { selected } = this.state

        const selectedResult = selected && resultValues[selected - 1]
        return (
            <Fragment>
                {options &&
                    options.map((option, index) => {
                        var mappedDrainage = mappedDrainageTypes.filter((x) => x.id === option)[0]
                        if (mappedDrainage !== undefined) {
                            return (
                                <label
                                    key={option}
                                    className={`drainage__display columns h-pt-sm small-4 grow-big pointer ${this.state.selected === option ? "selected" : ""}`}
                                >
                                    <span className="name">{mappedDrainage.name}</span>
                                    <img alt={mappedDrainage.name} src={mappedDrainage.image} />
                                    {resultValues && resultValues[index] !== undefined && <span className="count">{`${resultValues[index]} st`}</span>}
                                    <input
                                        type="radio"
                                        className="h-hide"
                                        name="selected"
                                        value={option}
                                        onChange={() => this.onValueChanged("selected", option)}
                                        required
                                    />
                                </label>
                            )
                        }
                        return null
                    })}
                <CSSTransitionGroup transitionName="fade-in" transitionEnterTimeout={500} transitionLeaveTimeout={300}>
                    {mappedOption && selectedResult && (
                        <div>
                            <label className="big thick-underline padding">{translate("calculator.ceilingarea.drainagetype.selection")}</label>
                            <label className="bigger amount">{`${selectedResult} st ${mappedOption.value.articleNumber}`}</label>
                            {productLink && (
                                <a href={productLink} target="_blank" rel="noreferrer">
                                    {translate("calculator.ceilingarea.drainagetype.showall")}
                                </a>
                            )}
                        </div>
                    )}
                </CSSTransitionGroup>
            </Fragment>
        )
    }
}

export default NwpDrainageDisplay
