import React, { Component } from "react"
import { connect } from "react-redux"
import MiniCart from "../Components/MiniCart"
import { toggle, update, load } from "../Actions/Cart.action"

class MiniCartContainer extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        let tempQuantity = sessionStorage.getItem("cartQuantity")
        if (tempQuantity && tempQuantity !== this.props.quantity) {
            this.props.load()
        }
    }

    render() {
        return <MiniCart {...this.props} />
    }
}

const mapStateToProps = (state) => {
    const { cart, checkout } = state
    return {
        ...cart,
        isBusinessCustomer: checkout.payload.isBusinessCustomer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggle: () => dispatch(toggle()),
        updateCart: (orderid, quantity) => dispatch(update(orderid, quantity)),
        load: () => dispatch(load()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MiniCartContainer)
