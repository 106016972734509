import React, { Component } from "react"
import { connect } from "react-redux"
import NwpCalculator from "../Components/Calculator/NwpCalculator"
import {
    load,
    setVariable,
    calculate,
    increaseRoofAreaCount,
    decreaseRoofAreaCount,
    removeRoofArea,
    mutateRoofArea,
    requestPdf,
} from "../Actions/NwpCalculator.action"

const whiteListProps = ["selectedMunicipality", "selectedSurfaceCoefficient", "selectedTime", "selectedYear", "selectedRiskFactor"]

class NwpCalculatorContainer extends Component {
    componentDidMount() {
        this.props.load(this.props.company, this.props.blockId)

        if (this.props.company) {
            this.setTitleAndFavicon(this.props.company)
        }
    }

    setTitleAndFavicon(company) {
        let favicon = document.getElementById("favicon")
        if (favicon) {
            document.title = company
            favicon.href = company + ".ico"
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.company !== this.props.company) {
            this.setTitleAndFavicon(this.props.company)
        }

        let shouldCalculate = false

        for (let prop in whiteListProps) {
            let elem = whiteListProps[prop]
            if (this.props.data[elem] !== prevProps.data[elem]) {
                shouldCalculate = true
            }
        }

        if (shouldCalculate) {
            for (var i in this.props.data.selectedRoofAreas) {
                let elem = this.props.data.selectedRoofAreas[i]
                if (elem && elem.complete) {
                    this.props.calculate(null, i)
                }
            }
        }
    }

    render() {
        return <NwpCalculator {...this.props} />
    }
}

const mapStateToProps = (state) => {
    let data = state.calculator

    if (data.riskFactors) {
        let options = {
            riskFactorOptions: data.riskFactors.map((riskFactor, index) => {
                return { label: `${index + 1}. ${riskFactor.name}`, value: riskFactor }
            }),

            surfaceCoefficientOptions: data.surfaceCoefficients.map((surfaceCoefficient, index) => {
                return { label: `${index + 1}. ${surfaceCoefficient.name}`, value: surfaceCoefficient }
            }),

            municipalityOptions: data.municipalities.map((municipality, index) => {
                return { label: `${municipality.name}`, value: municipality }
            }),

            timeOptions: data.times.map((time, index) => {
                return { label: `${time.time} minuter`, value: time }
            }),

            yearOptions: data.times.map((time, index) => {
                return { label: `${time.year} år`, value: time }
            }),

            flowCapacityOptions: data.flowCapacities.map((flowCapacity, index) => {
                return { label: `${index + 1}. ${flowCapacity.name}`, value: flowCapacity }
            }),
        }

        return { data, options }
    }
    return { data }
}

const mapDispatchToProps = (dispatch) => {
    return {
        load: (company, blockId) => dispatch(load(company, blockId)),
        setVariable: (variable, data) => dispatch(setVariable(variable, data)),
        calculate: (event, index) => dispatch(calculate(event, index)),
        increaseRoofAreaCount: () => dispatch(increaseRoofAreaCount()),
        decreaseRoofAreaCount: () => dispatch(decreaseRoofAreaCount()),
        removeRoofArea: (roofArea) => dispatch(removeRoofArea()),
        mutateRoofArea: (index, value) => dispatch(mutateRoofArea(index, value)),
        requestPdf: (event, company) => dispatch(requestPdf(event, company)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NwpCalculatorContainer)
