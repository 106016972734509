import { get, post, put } from "../Services/http"
import { load as loadCart } from "./Cart.action"
import { catchError } from "./Error.action"

export const CHECKOUT_SET_SELECTED_COMPANY_ADDRESS = "CHECKOUT_SET_SELECTED_COMPANY_ADDRESS"
export const CHECKOUT_SET_SELECTED_DELIVERY_ADDRESS = "CHECKOUT_SET_SELECTED_DELIVERY_ADDRESS"
export const CHECKOUT_SET_CUSTOMER_INFO = "CHECKOUT_SET_CUSTOMER_INFO"
export const CHECKOUT_SET_PRIVATE_CUSTOMER = "CHECKOUT_SET_PRIVATE_CUSTOMER"
export const CHECKOUT_SET_SIGN_UP = "CHECKOUT_SET_SIGN_UP"
export const CHECKOUT_SET_DELIVERY = "CHECKOUT_SET_DELIVERY"
export const CHECKOUT_SET_PAYMENT = "CHECKOUT_SET_PAYMENT"
export const CHECKOUT_SET_CAMPAIGN_CODE = "CHECKOUT_SET_CAMPAIGN_CODE"
export const CHECKOUT_SET_ORDER_NOTE = "CHECKOUT_SET_ORDER_NOTE"
export const CHECKOUT_ACCEPT_TERMS_OF_CONDITION = "CHECKOUT_ACCEPT_TERMS_OF_CONDITION"
export const CHECKOUT_SUBMIT = "CHECKOUT_SUBMIT"
export const CHECKOUT_SUBMIT_DONE = "CHECKOUT_SUBMIT_DONE"
export const CHECKOUT_SUBMIT_ERROR = "CHECKOUT_SUBMIT_ERROR"
export const CHECKOUT_SET_PAYMENT_WIDGET = "CHECKOUT_SET_PAYMENT_WIDGET"
export const CHECKOUT_GET_DELIVERY_VARIANTS = "CHECKOUT_GET_DELIVERY_VARIANTS"
export const CHECKOUT_RECIEVE_DELIVERY_VARIANTS = "CHECKOUT_RECIEVE_DELIVERY_VARIANTS"
export const CHECKOUT_RECIEVE_ADDITIONAL_ORDER_INFORMATION = "CHECKOUT_RECIEVE_ADDITIONAL_ORDER_INFORMATION"
export const CHECKOUT_RECIEVE_ADDITIONAL_SHIPPING_INFORMATION = "CHECKOUT_RECIEVE_ADDITIONAL_SHIPPING_INFORMATION"
export const CHECKOUT_SET_ALTERNATIVE_ADDRESS = "CHECKOUT_SET_ALTERNATIVE_ADDRESS"

export const getDeliveryVariants = () => (dispatch, getState) => {
    return get("/api/checkout/getdeliveryvariants")
        .then((response) => response.json())
        .then((deliveryVariants) => dispatch(recieveDeliveryVariants(deliveryVariants)))
}

export const recieveDeliveryVariants = (deliveryVariants) => ({
    type: CHECKOUT_RECIEVE_DELIVERY_VARIANTS,
    payload: {
        deliveryVariants,
    },
})

export const setAdditionalOrderInformation = (key, value) => (dispatch, getState) => {
    return put("/api/checkout/setAdditionalOrderInformation", { key: key, value: value })
        .then((response) => response.json())
        .then((additionalOrderInformation) => dispatch(recieveAdditionalOrderInformation(additionalOrderInformation)))
}

export const recieveAdditionalOrderInformation = (additionalOrderInformation) => ({
    type: CHECKOUT_RECIEVE_ADDITIONAL_ORDER_INFORMATION,
    payload: {
        additionalOrderInformation,
    },
})

export const setAdditionalShippingInformation = (zipCode) => (dispatch, getState) => {
    return put(`/api/checkout/setAdditionalShippingInformation?zipCode=${zipCode}`)
        .then((response) => response.json())
        .then((x) => {
            if (x !== "INVALID") {
                dispatch(recieveAdditionalShippingInformation(x))
            }
        })
}

export const recieveAdditionalShippingInformation = (shippingInfo) => ({
    type: CHECKOUT_RECIEVE_ADDITIONAL_SHIPPING_INFORMATION,
    payload: {
        shippingInfo,
    },
})

export const setBusinessCustomer = (isBusinessCustomer) => ({
    type: CHECKOUT_SET_PRIVATE_CUSTOMER,
    payload: {
        isBusinessCustomer,
    },
})

export const setSignUp = (signUp) => ({
    type: CHECKOUT_SET_SIGN_UP,
    payload: {
        signUp,
    },
})

export const setAlternativeAddress = (propName, value) => ({
    type: CHECKOUT_SET_CUSTOMER_INFO,
    payload: {
        key: "alternativeAddress",
        data: {
            [propName]: value,
        },
    },
})

export const setCustomerDetails = (propName, value) => ({
    type: CHECKOUT_SET_CUSTOMER_INFO,
    payload: {
        key: "customerDetails",
        data: {
            [propName]: value,
        },
    },
})

export const setSelectedCompanyAddress = (selectedCompanyAddressId) => ({
    type: CHECKOUT_SET_SELECTED_COMPANY_ADDRESS,
    payload: {
        selectedCompanyAddressId,
    },
})

export const setSelectedDeliveryAddress = (selectedDeliveryAddressId) => ({
    type: CHECKOUT_SET_SELECTED_DELIVERY_ADDRESS,
    payload: {
        selectedDeliveryAddressId,
    },
})

export const setDelivery = (systemId) => (dispatch, getState) => {
    dispatch({
        type: CHECKOUT_SET_DELIVERY,
        payload: {
            selectedDeliveryMethod: systemId,
        },
    })
    const { payload } = getState().checkout
    return put("/api/checkout/setDeliveryProvider", payload)
        .then((response) => response.json())
        .then(() => dispatch(loadCart()))
        .catch((ex) => dispatch(catchError(ex, (error) => submitError(error))))
}

export const setPayment = (systemId) => (dispatch, getState) => {
    dispatch({
        type: CHECKOUT_SET_PAYMENT,
        payload: {
            selectedPaymentMethod: systemId,
        },
    })
    const { payload } = getState().checkout
    return put("/api/checkout/setPaymentProvider", payload)
        .then((response) => response.json())
        .then((result) => {
            dispatch(loadCart())
            dispatch(setPaymentWidget(result.paymentWidget))
        })
        .catch((ex) => dispatch(catchError(ex, (error) => submitError(error))))
}

export const reloadPayment = () => (dispatch, getState) => {
    const { paymentWidget, selectedPaymentMethod } = getState().checkout.payload
    if (!paymentWidget) {
        return
    }
    return dispatch(setPayment(selectedPaymentMethod))
}

const setPaymentWidget = (paymentWidget) => ({
    type: CHECKOUT_SET_PAYMENT_WIDGET,
    payload: {
        paymentWidget,
    },
})

export const setOrderNote = (orderNote) => (dispatch, getState) => {
    dispatch({
        type: CHECKOUT_SET_ORDER_NOTE,
        payload: {
            orderNote,
        },
    })
    return put(`/api/checkout/updateOrderComment?orderNote=${orderNote}`)
        .then((response) => response.json())
        .catch((ex) => dispatch(catchError(ex, (error) => submitError(error))))
}

export const acceptTermsOfCondition = (acceptTermsOfCondition) => ({
    type: CHECKOUT_ACCEPT_TERMS_OF_CONDITION,
    payload: {
        acceptTermsOfCondition,
    },
})

export const setCampaignCode = (campaignCode) => ({
    type: CHECKOUT_SET_CAMPAIGN_CODE,
    payload: {
        campaignCode,
    },
})

export const submitCampaignCode = () => (dispatch, getState) => {
    const { payload } = getState().checkout
    return put("/api/checkout/setCampaignCode", payload)
        .then((response) => response.json())
        .then((result) => {
            dispatch(loadCart())
            // Reset error of campaign code
            dispatch(
                submitError({
                    modelState: {
                        campaignCode: [],
                    },
                })
            )
        })
        .catch((ex) => {
            dispatch(catchError(ex, (error) => submitError(error)))
            // Restore the initial cart
            dispatch(loadCart())
        })
}

export const submit = () => (dispatch, getState) => {
    const { payload } = getState().checkout
    return _submit("/api/checkout", payload, dispatch)
}

export const verify = (url, orderId, payload) => (dispatch, getState) => {
    const model = getState().checkout.payload
    model.orderId = orderId
    model.payload = payload
    return _submit(url, model, dispatch)
}

const _submit = (url, model, dispatch) => {
    return post(url, model)
        .then((response) => response.json())
        .then((result) => {
            dispatch(submitDone(result))
        })
        .catch((ex) => {
            if (ex.response) {
                ex.response.json().then((error) => {
                    dispatch(submitError(error))
                    dispatch(submitDone(null))
                })
            } else {
                dispatch(submitError(ex))
            }
        })
}

export const submitRequest = (isPickup) => ({
    type: CHECKOUT_SUBMIT,
    payload: {
        isSubmitting: true,
        isPickup: isPickup,
        errors: [],
    },
})

export const submitDone = (result) => ({
    type: CHECKOUT_SUBMIT,
    payload: {
        result,
        isSubmitting: false,
    },
})

export const submitError = (error) => ({
    type: CHECKOUT_SUBMIT_ERROR,
    payload: {
        error,
        isSubmitting: false,
    },
})

export const setAlternativeFullAddress = (address, organizationName = null) => ({
    type: CHECKOUT_SET_ALTERNATIVE_ADDRESS,
    payload: {
        alternativeAddress: address
            ? {
                  organizationName,
                  address: address.address,
                  zipCode: address.zipCode,
                  city: address.city,
                  country: address.country,
                  save: false,
              }
            : {},
    },
})
