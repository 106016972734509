import { DOCUMENTS_RECEIVE, SET_ACTIVE_FOLDER } from "../Actions/Documents.action"

const defaultState = {
    rootFolder: null,
    activeFolder: null,
    folderCache: [],
}

export const documents = (state = defaultState, action) => {
    const { type, payload } = action

    if (type === DOCUMENTS_RECEIVE) {
        state.folderCache.push(payload.folder)
        if (!state.rootFolder) {
            state.rootFolder = payload.folder
        }
        delete payload.folder
    }

    switch (type) {
        case DOCUMENTS_RECEIVE:
        case SET_ACTIVE_FOLDER:
            return {
                ...state,
                ...payload,
            }
        default:
            return state
    }
}
