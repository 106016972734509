import { get, post, put } from "../Services/http"
import { catchError } from "./Error.action"

export const NWP_CALCULATOR_LOAD = "NWP_CALCULATOR_LOAD"
export const NWP_CALCULATOR_LOAD_ERROR = "NWP_CALCULATOR_LOAD_ERROR"
export const NWP_CALCULATOR_RECEIVE = "NWP_CALCULATOR_RECEIVE"

export const NWP_CALCULATOR_SETVAR = "NWP_CALCULATOR_SETVAR"

export const load = () => (dispatch, getState) => {
    return get("/api/calculator/GetCalculatorData")
        .then((response) => response.json())
        .then((data) => dispatch(receive(data)))
        .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))))
}

export const loadError = (error) => ({
    type: NWP_CALCULATOR_LOAD_ERROR,
    payload: {
        error,
    },
})

export const receive = (data) => ({
    type: NWP_CALCULATOR_RECEIVE,
    payload: data,
})

export const setVariable = (variable, data) => ({
    type: NWP_CALCULATOR_SETVAR,
    payload: {
        [variable]: data,
    },
})
