import { FACETED_SEARCH_QUERY } from "../Actions/FacetedSearch.action"

export const historyMiddleware = (store) => (next) => (action) => {
    const { type, payload } = action
    switch (type) {
        case FACETED_SEARCH_QUERY:
            let { query } = payload
            var decodedUri = decodeURI(query)
            const url = window.location.href.replace(window.location.search, "") + encodeURI(`${decodedUri ? "?" : ""}${decodedUri}`)
            window.history.pushState("search", "Search Page", url)
            break
    }
    next(action)
}
