import React, { Fragment, Component } from "react"
import { translate } from "../Services/translation"
import { evaluate } from "mathjs"

class NumericUpDown extends Component {
    constructor(props) {
        super(props)

        let initialValue = props.initialValue ? JSON.parse(props.initialValue.toString().replace(",", ".")) : null
        let multiple = props.initialValue ? JSON.parse(props.multiple.toString().replace(",", ".")) : null

        this.state = {
            // Use the value of the quantity property if it's set, otherwise use the initial value, or the min value
            value:
                this.props.quantity !== undefined
                    ? this.props.quantity
                    : initialValue !== null
                    ? initialValue
                    : this.props.minValue
                    ? Number(this.props.minValue)
                    : 1,

            multiple: !isNaN(multiple) ? multiple : 1,
            multipleIgnore: false,
            valueHasChanged: false,
            singlesAllowed: this.props.onlyFullPackage !== "true" && this.getCookie("warehouseId") !== "104",
        }

        this.skipExtraQuantityUpdate = false
    }

    componentDidMount() {
        /*const visible = this.refs.list.getBoundingClientRect().height !== 0;
        const tooMuchItem = this.props.group.options.length > this.state.showLessItemCount;
        visible && tooMuchItem && this.toggleShowMore();*/
    }

    componentDidUpdate(prevProps) {
        // Check if the property you're interested in has changed
        if (prevProps.quantity !== this.props.quantity) {
            // If it has changed, update the state accordingly
            this.setState({
                value: this.props.quantity,
                valueHasChanged: true,
            })
        }
    }

    clamp(a, b, c) {
        return Math.max(b, Math.min(c, a))
    }

    getCookie = (name) => {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(";").shift()
    }

    changeQuantity(newQuantity, skipRound = false) {
        if (isNaN(newQuantity)) {
            return
        }

        let min = this.props.minValue ? this.props.minValue : 1
        let max = this.props.maxValue ? this.props.maxValue : Number.MAX_SAFE_INTEGER
        let newValue = this.clamp(newQuantity, min, max)

        if (!skipRound) {
            newValue = this.roundToNearestMultiple(newValue, this.state.multiple)
        }

        this.setState({
            value: newValue,
            valueHasChanged: true,
        })

        if (this.props.onChange) {
            this.props.onChange(newValue)
        }
    }

    roundToNearestMultiple(value, multiple, decrease = false) {
        value = value > 0 ? value : 1 // Prevents quantity to turn into zero, potentially deleting items in cart on blur
        let nearestMultiple = decrease ? Math.floor(value / multiple) * multiple - multiple : Math.ceil(value / multiple) * multiple
        if (this.props.decimals > 0) {
            nearestMultiple = parseFloat(nearestMultiple.toFixed(this.props.decimals))
        }
        return nearestMultiple
    }

    doChangeQuantity(currentValue, amount) {
        if (this.skipExtraQuantityUpdate) {
            this.skipExtraQuantityUpdate = false
            return
        }

        var newValue = 0
        var addition = amount > 0
        var multiple = this.state.multiple
        var roundedDownValue = this.roundToNearestMultiple(currentValue, multiple, true)
        var roundedUpValue = this.roundToNearestMultiple(currentValue, multiple)

        if (currentValue >= this.props.maxValue && addition) {
            newValue = roundedDownValue
        } else if (currentValue <= this.props.minValue && !addition) {
            newValue = roundedUpValue
        } else if (!this.state.singlesAllowed || multiple === Math.abs(amount)) {
            // TODO: there are rounding issues when dealing with more than one decimal
            if (addition) {
                newValue = roundedUpValue === currentValue ? roundedUpValue + amount : roundedUpValue
            } else {
                newValue = roundedDownValue === currentValue ? roundedDownValue + amount : roundedDownValue
            }
        } else {
            newValue = parseInt(currentValue) + amount
        }

        this.changeQuantity(newValue, true)
    }

    doMultipleIgnore = () => {
        this.setState({
            multipleIgnore: true,
        })
    }

    quantityEqualsWholePackage = (quantity, multiple) => {
        var quantityDecimalCount = (quantity.toString().split(".")[1] ?? "").length
        var multipleDecimalCount = (multiple.toString().split(".")[1] ?? "").length

        if (quantityDecimalCount === 0 && multipleDecimalCount === 0) {
            return quantity % multiple === 0
        }

        var decimalCount = quantityDecimalCount > multipleDecimalCount ? quantityDecimalCount : multipleDecimalCount
        var quantityInt = parseInt(quantity.toFixed(decimalCount).replace(".", ""))
        var multipleInt = parseInt(multiple.toFixed(decimalCount).replace(".", ""))
        return quantityInt % multipleInt === 0
    }

    render() {
        const { quantity, inputId, dataExpression, dataExpressionFormat, dataExpressionQuantityName, decimals } = this.props
        const { multiple, multipleIgnore, valueHasChanged, singlesAllowed } = this.state

        const useExternalVariable = false

        let inputValue = useExternalVariable ? quantity : this.state.value
        let value = inputValue || 0

        if (decimals > 0) {
            value = Math.round(value * (decimals * 10)) / (decimals * 10)
        } else {
            value = parseInt(value)
        }

        const decreaseQuantity = () => this.doChangeQuantity(value, -1)
        const increaseQuantity = () => this.doChangeQuantity(value, 1)

        const decreaseMultipleQuantity = () => this.doChangeQuantity(value, -multiple)
        const increaseMultipleQuantity = () => this.doChangeQuantity(value, multiple)

        const disabledDown = value <= (this.props.minValue ? this.props.minValue : 1)
        const disabledUp = value >= (this.props.maxValue ? this.props.maxValue : Number.MAX_SAFE_INTEGER)

        const format = dataExpressionFormat ? translate(dataExpressionFormat) : ""

        const formattedDataExpression = dataExpression ? dataExpression.replace("%val%", value) : ""
        const evalutedExpression = evaluate(formattedDataExpression)

        const splitQuantityName =
            dataExpressionQuantityName && dataExpressionQuantityName.includes(",") ? dataExpressionQuantityName.split(",") : dataExpressionQuantityName

        const evalExpression = (Math.round(100 * eval(evalutedExpression)) / 100).toString().replace(".", ",")

        const finalString = format ? `${format.format(value, value > 1 ? splitQuantityName[1] : splitQuantityName[0], evalExpression)}` : evalExpression

        const isDeviant = !this.quantityEqualsWholePackage(value, multiple)
        const closestPackageQuantity = Math.ceil(value / multiple) * multiple

        let step
        switch (decimals) {
            case 1:
                step = ".1"
                break
            case 2:
                step = ".01"
                break
            case 3:
                step = ".001"
                break
        }

        // Use the calculated value of "value" if it's larger than 0. This allows for the input to be blank
        inputValue = value > 0 ? value : inputValue

        return (
            <Fragment>
                <div className={"numeric-input " + (multiple && multiple > 1 ? "has-multiple" : "")} role="numeric">
                    {multiple && multiple > 1 && (
                        <button type="button" className="numeric-input__button down multiple" disabled={value <= multiple} onClick={decreaseMultipleQuantity} />
                    )}
                    {singlesAllowed && <button type="button" className="numeric-input__button down" disabled={disabledDown} onClick={decreaseQuantity} />}
                    <input
                        type="number"
                        id={inputId}
                        className="numeric-input__value"
                        value={inputValue}
                        onBlur={(event) => {
                            this.skipExtraQuantityUpdate = true // We don't want to update the value twice when blur is triggered by clicking the buttons
                            this.changeQuantity(event.target.value, singlesAllowed)
                        }}
                        onChange={(event) =>
                            this.setState({
                                value: event.target.value < 0 && event.target.value !== "" ? 0 : event.target.value, // Prevent negative values, and still allow for empty ones
                                valueHasChanged: true,
                            })
                        }
                        step={singlesAllowed ? step : multiple}
                    />
                    {singlesAllowed && <button type="button" className="numeric-input__button up" disabled={disabledUp} onClick={increaseQuantity} />}
                    {multiple && multiple > 1 && (
                        <button type="button" className="numeric-input__button up multiple" disabled={disabledUp} onClick={increaseMultipleQuantity} />
                    )}
                    {isDeviant && valueHasChanged && !multipleIgnore && (
                        <div className="alert numeric-input__alert">
                            <button type="button" className="alert__close" onClick={this.doMultipleIgnore} />
                            <p dangerouslySetInnerHTML={{ __html: "Valt antal är ej hel pall/förpackning." }} />
                            <a onClick={() => this.changeQuantity(closestPackageQuantity)} href="javascript:;">
                                <span dangerouslySetInnerHTML={{ __html: `Avrunda till hel pall/förpackning.` }} />
                            </a>
                        </div>
                    )}
                </div>
                {evalutedExpression > 0 && (
                    <div className="numeric-input__expression">
                        <span>{finalString}</span>
                    </div>
                )}
            </Fragment>
        )
    }
}

export default NumericUpDown
