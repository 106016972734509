import React, { Component, Fragment } from "react"
import Select, { components } from "react-select"
import NwpMenuList from "./NwpMenuList"

class NwpCalculatorSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.value !== this.props.value
    }

    componentDidMount() {
        if (this.props.defaultValue !== undefined) {
            this.props.onChange(this.props.options[this.props.defaultValue])
        }
    }

    render() {
        const { options, value, onChange, placeholder, defaultValue } = this.props
        const handleOnChange = (value) => {
            onChange(value)
        }

        const CustomInput = (props) => <components.Input {...props} value={value?.label} required />

        return (
            <Select
                placeholder={placeholder !== undefined ? placeholder : ""}
                value={value}
                onChange={handleOnChange}
                options={options}
                components={{ NwpMenuList, Input: CustomInput }}
                classNamePrefix="calculator"
                defaultValue={options[defaultValue]}
            />
        )
    }
}

export default NwpCalculatorSelect
