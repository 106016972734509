import { get } from "../Services/http"
import { catchError } from "./Error.action"

export const DOCUMENTS_RECEIVE = "DOCUMENTS_RECEIVE"
export const SET_ACTIVE_FOLDER = "SET_ACTIVE_FOLDER"

const rootRoute = "/api/documents"

export const query = (id, parentId) => (dispatch) => {
    const url = id ? `${rootRoute}?id=${id}` : rootRoute
    return get(url)
        .then((response) => response.json())
        .then((result) => {
            result.parent = parentId
            dispatch(receive(result))
        })
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))
}

export const receive = (folder) => {
    return {
        type: DOCUMENTS_RECEIVE,
        payload: {
            folder,
            activeFolder: folder,
        },
    }
}

export const setActive = (activeFolder) => {
    return {
        type: SET_ACTIVE_FOLDER,
        payload: {
            activeFolder,
        },
    }
}

export const setError = (error) => {
    alert(error.message || error)
    console.error(error)
}
