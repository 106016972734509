import React, { Component } from "react"
import { connect } from "react-redux"
import QuickSearch from "../Components/QuickSearch"
import { query, toggleShowFullForm, handleKeyDown } from "../Actions/QuickSearch.action"

class QuickSearchContainer extends Component {
    constructor(props) {
        super(props)
        this.state = { ...props }
        this.clickHandler = this.clickHandler.bind(this)
        this.lastClickedNode = null
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.clickHandler)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.clickHandler)
    }

    componentWillUpdate({ showFullForm } = props) {
        if (this.state.showFullForm !== showFullForm) {
            this.setState({ ...this.state, showFullForm })
        }
    }

    clickHandler(event) {
        this.lastClickedNode = event.target
    }

    onBlur() {
        const { showFullForm, toggleShowFullForm } = this.props
        if (this.searchContainer && !this.searchContainer.contains(this.lastClickedNode)) {
            showFullForm && toggleShowFullForm()
        }
    }

    render() {
        const { query } = this.props
        const searchUrl = window.__litium.quickSearchUrl + (query.length > 0 ? `?q=${query}` : "")
        return (
            <div ref={(elem) => (this.searchContainer = elem)}>
                <QuickSearch {...{ ...this.props, searchUrl, onBlur: () => this.onBlur() }} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        query: state.quickSearch.query,
        result: state.quickSearch.result,
        showResult: state.quickSearch.showResult,
        showFullForm: state.quickSearch.showFullForm,
        selectedItem: state.quickSearch.selectedItem,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSearch: (text, signal) => dispatch(query(text, signal)),
        toggleShowFullForm: () => dispatch(toggleShowFullForm()),
        onKeyDown: (event, opt) => dispatch(handleKeyDown(event, opt)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickSearchContainer)
