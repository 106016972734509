export const translate = (key) => window.__litium.translation[key] || key
export const translationExists = (key) => !!window.__litium.translation[key]

export const translateWithParams = (textKey, params) => {
    let text = translate(textKey)
    for (let i = 0; i < params.length; i++) {
        text = text.replace(`{${i}}`, params[i])
    }
    return text
}
