export const get = (url) => _fetch(url, "GET")

export const post = (url, data) => _fetch(url, "POST", data)
export const put = (url, data) => _fetch(url, "PUT", data)
export const patch = (url, data) => _fetch(url, "PATCH", data)
export const remove = (url, data) => _fetch(url, "DELETE", data)
export const postAbortable = (url, data, signal) => _fetchAbortable(url, "POST", data, signal)

const _fetch = (url, method, data = null) => {
    let options = {
        method,
        headers: {
            "litium-request-context": JSON.stringify(window.__litium.requestContext),
        },
        credentials: "include",
    }
    if (method !== "GET") {
        options = {
            ...options,
            body: data ? JSON.stringify(data) : null,
            headers: {
                ...options.headers,
                "content-type": "application/json",
            },
        }
    }
    const token = window.localStorage.getItem("requestVerificationToken")
    if (token) {
        options = {
            ...options,
            headers: {
                ...options.headers,
                RequestVerificationToken: token,
            },
        }
    }
    return fetch(url, options).then(_checkStatus)
}

const _fetchAbortable = (url, method, data = null, signal = null) => {
    let options = {
        method,
        headers: {
            "litium-request-context": JSON.stringify(window.__litium.requestContext),
        },
        credentials: "include",
        signal: signal,
    }
    if (method !== "GET") {
        options = {
            ...options,
            body: data ? JSON.stringify(data) : null,
            headers: {
                ...options.headers,
                "content-type": "application/json",
            },
        }

        const token = window.localStorage.getItem("requestVerificationToken")
        if (token) {
            options = {
                ...options,
                headers: {
                    ...options.headers,
                    RequestVerificationToken: token,
                },
            }
        }
    }
    return fetch(url, options).then(_checkStatus)
}

const _checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return response
    } else {
        let error = new Error(response.statusText)
        error.response = response
        throw error
    }
}
