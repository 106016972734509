import { CART_RECEIVE, CART_SHOW_INFO, CART_USES_MOCK } from "../Actions/Cart.action"
import { CHECKOUT_RECIEVE_ADDITIONAL_SHIPPING_INFORMATION } from "../Actions/Checkout.action"

export const cart = (state = { quantity: 0, orderTotal: 0, showInfo: false, usesMockAddress: false }, action) => {
    switch (action.type) {
        case CHECKOUT_RECIEVE_ADDITIONAL_SHIPPING_INFORMATION:
        case CART_RECEIVE:
        // if (state.usesMockAddress && !action.payload.shippingInfo.isMockAddress)
        // {
        //     var newObject = {...state, ...action.payload}

        //     newObject.shippingInfo = state.shippingInfo;

        //     return newObject;
        // }
        // else
        // {
        //     return {
        //         ...state,
        //         ...action.payload,
        //     };
        // }
        case CART_USES_MOCK:
        case CART_SHOW_INFO:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}
