import React, { Component } from "react"
import QuickSearchResult from "./QuickSearchResult"
import { translate } from "../Services/translation"
import Icon from "./Icon"

class QuickSearch extends Component {
    focusOnInput() {
        setTimeout(() => {
            this.searchInput && this.searchInput.focus()
        }, 0)
    }

    previousController = null

    onSearchLocal(text) {
        if (text != null && text != undefined) {
            if (this.previousController) {
                this.previousController.abort()
            }
            const controller = new AbortController()
            const signal = controller.signal
            this.previousController = controller
            this.props.onSearch(text, signal)
        }
    }

    render() {
        const { query, result, showResult, showFullForm, onSearch, onBlur, onKeyDown, toggleShowFullForm, selectedItem, searchUrl } = this.props
        return (
            <div className={`quick-search ${!showFullForm ? "" : "active"}`} role="search">
                <button
                    className="quick-search__link--block h-cursor-pointer"
                    onClick={() => {
                        toggleShowFullForm()
                        this.focusOnInput()
                    }}
                    type="button"
                >
                    <span className="show-for-sr">{translate("general.search")}</span>
                    <Icon name="search" size="22" />
                </button>
                <div className={`quick-search__form ${showFullForm ? "quick-search__form--force-show" : ""}`} role="search">
                    <div className="quick-search__form-inner">
                        <div className="quick-search__input-container">
                            <label htmlFor="quick-search-input" className="show-for-sr">
                                {translate("general.search")}
                            </label>
                            <input
                                id="quick-search-input"
                                className="quick-search__input"
                                type="search"
                                placeholder={translate("general.search")}
                                autoComplete="off"
                                value={query}
                                onChange={(event) => this.onSearchLocal(event.target.value)}
                                onKeyDown={(event) => onKeyDown(event, { searchUrl })}
                                ref={(input) => {
                                    this.searchInput = input
                                }}
                                onBlur={() => onBlur()}
                            />
                        </div>
                        {showResult && <QuickSearchResult result={result} selectedItem={selectedItem} searchUrl={searchUrl} />}
                    </div>
                </div>
                <div className="hover__component"></div>
            </div>
        )
    }
}

export default QuickSearch
