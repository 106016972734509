export const error = (state = {}, action) => {
    const { error } = action.payload
    if (!error) {
        return state
    }

    if (error.modelState) {
        return error.modelState
    }

    // This handles the case where using Yup validation with the abortEarly option set to true (as in: .validate(person, { abortEarly: true }))
    // It's worth noting that if you don't explicitly set the abortEarly option, it will behave the same way.
    if (error.name === "ValidationError" && !error.inner.length) {
        return {
            [error.path]: error.errors,
        }
    }

    // This handles the case where using Yup validation with the abortEarly option set to false (as in: .validate(person, { abortEarly: false }))
    // This enables us to show all validation errors at once instead of one at a time.
    if (error.name === "ValidationError" && error.inner.length) {
        const errors = error.inner.reduce((acc, current) => {
            if (!acc[current.path]) {
                acc[current.path] = []
            }
            acc[current.path].push(current.message)
            return acc
        }, {})

        return errors
    }

    // This handles the case where we get back a validation error from the server, such as a faulty phone number - {"CustomerDetails.phoneNumber":["Telefon"]}
    if (error.name !== "ValidationError" && typeof error === "object") {
        const errorFormatted = {}
        Object.keys(error).forEach((key) => {
            errorFormatted[_camelCase(key)] = error[key]
        })
        return errorFormatted
    }

    return state
}

const _camelCase = (input) => {
    if (typeof input !== "string") return ""
    return input.charAt(0).toLocaleLowerCase() + input.slice(1)
}
