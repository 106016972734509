import React, { Component } from "react"
import { FixedSizeList as List } from "react-window"

class NwpMenuList extends Component {
    render() {
        const { options, children, maxHeight, getValue } = this.props
        const [value] = getValue()
        const initialOffset = options.indexOf(value) * 50

        return (
            <List height={maxHeight} itemCount={children.length} itemSize={50} initialScrollOffset={initialOffset}>
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        )
    }
}

export default NwpMenuList
