import { MUNICIPALITIES_RECEIVE } from "../Actions/Municipalites.action"

const defaultState = {
    items: [],
    selectedItems: [],
    selectedKeys: [],
}

export const municipalities = (state = defaultState, action) => {
    const { type, payload } = action
    switch (type) {
        case MUNICIPALITIES_RECEIVE:
            return {
                ...state,
                ...payload,
            }
        default:
            return state
    }
}
