import { NWP_CALCULATOR_RECEIVE, NWP_CALCULATOR_SETVAR } from "../Actions/NwpCalculator.action"

export const calculator = (state = { selectedRoofAreas: [{}] }, action) => {
    switch (action.type) {
        case NWP_CALCULATOR_SETVAR:
        case NWP_CALCULATOR_RECEIVE:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}
