import Select from "react-select"
import $ from "jquery"
import "slick-carousel"

function heroMinHeight() {
    $(".hero-block").each((index, block) => {
        $(block).css("min-height", Math.round($(block).find(".overlay").outerHeight()))
    })
}

export function setUpSliders() {
    let resizeCounter
    $(window).resize(() => {
        clearTimeout(resizeCounter)
        resizeCounter = setTimeout(() => setUpSliders, 500)
    })

    const bannerBlockRows = document.querySelectorAll(".banner-block .row")
    if (bannerBlockRows.length > 0) {
        Array.from(bannerBlockRows).forEach((row) => {
            if (!row.classList.contains("slick-slider")) {
                $(row).slick({
                    mobileFirst: true,
                    slidesToShow: 1,
                    centerMode: true,
                    responsive: [
                        {
                            breakpoint: 0,
                            settings: {
                                centerPadding: "40px",
                            },
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                centerPadding: "150px",
                            },
                        },
                        {
                            breakpoint: 1023,
                            settings: "unslick",
                        },
                    ],
                })

                setTimeout(() => {
                    Array.from(row.querySelectorAll("video[autoplay]")).forEach((video) => {
                        video.play()
                    })
                }, 1000)
            }
        })
    }

    const productDetailMainImage = $(".product-detail__main-image-slider")
    const productDetailThumbs = $(".product-detail__image-thumbs")
    if (productDetailMainImage.length > 0) {
        productDetailMainImage.not(".slick-initialized").slick({
            slidesToShow: 1,
            arrows: false,
            infinite: false,
            asNavFor: ".product-detail__image-thumbs",
            draggable: false,
            speed: 200,
            fade: true,
            adaptiveHeight: true,
        })
        productDetailThumbs.not(".slick-initialized").slick({
            slidesToShow: 6,
            infinite: false,
            asNavFor: ".product-detail__main-image-slider",
            focusOnSelect: true,
            mobileFirst: false,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: "unslick",
                },
            ],
        })
    }

    const productCarousels = $(".product-carousel")
    if (productCarousels.length > 0) {
        productCarousels.each((index, elem) => {
            const slider = $(elem)
            const parent = slider.parent()

            slider.slick({
                slidesToShow: slider.data("slides-to-show") || 4,
                slidesToScroll: slider.data("slides-to-show") || 4,
                infinite: true,
                arrows: true,
                prevArrow: parent.find(".product-carousel__left-arrow"),
                nextArrow: parent.find(".product-carousel__right-arrow"),
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            })
        })
    }

    const sliderBlocks = $(".slider-block__slides")
    if (sliderBlocks.length > 0) {
        sliderBlocks.each((index, elem) => {
            const slider = $(elem)
            const autoplay = slider.data("autoplay") || false
            const speed = parseInt(slider.data("speed") || 10000)

            slider.slick({
                slidesToShow: 1,
                infinite: true,
                arrows: true,
                dots: true,
                autoplay: autoplay,
                autoplaySpeed: speed,
            })
        })
    }

    heroMinHeight()
    $(window).resize(function () {
        heroMinHeight()
    })
}

export function initSelect() {
    var options = []
    if (document.getElementById("variantSelectOptions")) {
        var optionData = JSON.parse(document.getElementById("variantSelectOptions").getAttribute("data-options"))
        optionData.forEach((obj) => options.push({ value: obj, label: obj }))
    }
    if (document.getElementById("variantSelect")) {
        ReactDOM.render(
            <Select
                className="variant-selector"
                classNamePrefix="inner"
                options={options}
                onChange={(obj) => {
                    let url = new URL(window.location)
                    url.searchParams.set("variant", obj.value)
                    window.location = url
                }}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: "lightgrey",
                        primary50: "grey",
                        primary: "black",
                    },
                })}
            />,
            document.getElementById("variantSelect")
        )
    }
}
