import React from "react"
import { translate } from "../Services/translation"
import CartProductViewer from "./CartProductViewer"
import Icon from "./Icon"

const deliveryMethodId = document.cookie.replace(/(?:(?:^|.*;\s*)deliveryMethod\s*\=\s*([^;]*).*$)|^.*$/, "$1")
const deliveryLabelKey = deliveryMethodId != "pickup" ? "minicart.shipping" : "checkout.order.servicecost"

const MiniCart = ({
    quantity,
    orderTotal,
    checkoutUrl,
    showInfo,
    toggle,
    orderRows,
    updateCart,
    deliveryCost,
    shippingInfo,
    grandTotal,
    discounts = 0,
    rawTotal = 0,
    isBusinessCustomer,
}) => (
    <div className={`cart cart--mini ${!showInfo ? "" : "active"}`}>
        <a href="#" className="cart__link--block" onClick={() => toggle()}>
            <Icon name="shopping-cart" size="22" />
            {quantity > 0 && <span className="cart__link-count">{quantity}</span>}
        </a>
        <div className={`cart__info-background opaque ${!showInfo ? "" : "active"}`} onClick={() => toggle()}></div>
        <div className={`cart__info ${!showInfo ? "cart__info--hidden" : ""}`}>
            <div className="cart__header row margin-bottom-1">
                <div className="columns">
                    <label className="cart__title">{translate("minicart.cart")}</label>
                </div>
                <div className="columns shrink">
                    <button type="button" className="cart__close-button" onClick={() => toggle()}></button>
                </div>
            </div>
            <div className="cart__body h-custom-scrollbar">
                {orderRows.length <= 0 ? (
                    <div className="cart__empty-container">{translate("minicart.empty")}</div>
                ) : (
                    orderRows
                        .filter((x) => !x.isDeliveryProduct)
                        .map((order, i) => {
                            if (order.discountPricePerUnit) {
                                discounts += order.discountTotal
                            }
                            rawTotal += order.rawPriceTotal
                            return <CartProductViewer orderRow={order} updateCart={updateCart} key={i}></CartProductViewer>
                        })
                )}
            </div>
            {orderRows.length > 0 ? (
                <div className="cart__footer">
                    {isBusinessCustomer && (
                        <div className="row row-spacing smallest cart__centered-row">
                            <div className="columns small-6 cart__info-row">
                                <label className="cart__shipping-total-label">{translate(deliveryLabelKey)}</label>
                            </div>
                            <div className="columns small-6 cart__info-shipping-container">
                                <label className="cart__shipping-total-value">
                                    {shippingInfo.formattedTotalDeliveryCost
                                        ? `${shippingInfo.formattedTotalDeliveryCost} ${
                                              !shippingInfo.usesLabelValue && !shippingInfo.isDeliveryCostFree ? " kr" : ""
                                          }`
                                        : translate("minicart.calculatingshipping")}
                                </label>
                            </div>
                        </div>
                    )}
                    {!isBusinessCustomer && <br />}
                    <div className="row row-spacing smallest cart__centered-row">
                        <div className="columns small-6 cart__info-row">
                            <label className="cart__price-total-label">{translate("minicart.total")}</label>
                        </div>
                        <div className="columns small-6 cart__info-price-container">
                            <label className="cart__price-total-value">{grandTotal}</label>
                        </div>
                    </div>
                    <div className="row-spacing">
                        <a href={checkoutUrl} className="btn btn--outlined btn--full-width">
                            {translate("minicart.checkout")}
                        </a>
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
        <div className="hover__component"></div>
    </div>
)

export default MiniCart
