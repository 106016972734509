import React, { Fragment } from "react"
import { translate } from "../Services/translation"

const sameCategory = (item, index, array) => (index > 0 && array[index - 1].category === array[index].category) || item.showAll

const QuickSearchResult = ({ result, selectedItem, searchUrl }) => (
    <ul className="quick-search-result">
        {result &&
            result.map((item, index, array) => (
                <Fragment key={`${item.name}-${index}`}>
                    {sameCategory(item, index, array) ? null : (
                        <li className="quick-search-result__item quick-search-result__group-header">{translate(`search.${item.category.toLowerCase()}`)}</li>
                    )}

                    <li className={item.showAll ? "" : `quick-search-result__item ${selectedItem === index ? "quick-search-result__item--selected" : ""}`}>
                        <a
                            className={
                                item.showAll
                                    ? "btn btn--outlined btn--outlined-on-dark h-mt-md h-display-block h-text-center"
                                    : `quick-search-result__link h-mt-sm ${item.url ? "" : "quick-search-result__link--disabled"}`
                            }
                            href={item.showAll ? searchUrl : item.url}
                        >
                            {item.hasImage && item.imageSource && <img className="quick-search-result__image" src={item.imageSource} />}
                            {!item.showAll ? <div>{item.name}</div> : item.name}
                        </a>
                    </li>
                </Fragment>
            ))}
    </ul>
)

export default QuickSearchResult
